/* eslint-disable jsx-a11y/anchor-is-valid */
 
import {FC} from 'react'
 


type Props = {
  clinicPDate: any
}
 
 const ClinicPaymentDate: FC<Props> = ({clinicPDate}) =>
(
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className=''>
    { new Date(clinicPDate?.paymentDate).toLocaleDateString("en-GB", { day: "numeric",  month: "short",year: "numeric" })}
       
    </div> 
    
  </div>
)

const ClinicPaymentStatus: FC<Props> = ({clinicPDate}) =>
(
  <div className='d-flex align-items-center'>
      
      <div  className='text-dark fw-bold  fs-6'>
                      {clinicPDate.status=="2" ? (
                         <a className='btn btn-sm btn-light-danger fw-bolder ms-2 fs-8 py-1 px-3' >
                        Invoice Created
                       </a>
                      ):(
                      
                        <a className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3' >
                        Payment Submitted
                      </a>
                        )}
                      </div>
    </div>
) 

export { ClinicPaymentDate,ClinicPaymentStatus}
