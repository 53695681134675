/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, KTSVG, QUERIES } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
 
import { ListClinic } from '../../core/_models'
import { useNavigate } from 'react-router-dom'
import { Modal, Button } from "react-bootstrap";
import { useAuth } from '../../../../../auth'
import { getCRUD } from '../../../../companies/company-list/core/_requests'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Props = {
  clinic: ListClinic
}

const ClinicActionsCell: FC<Props> = ({ clinic }) => {
  //
  const navigate = useNavigate()
  const [showModal, setShow] = useState(false);
 const handleClose = () => setShow(false);


   const viewDetails = () => {
    if(roleaccess?.view===0){  
      toast.error('You do not have permission to access this page', {
        position: "top-right",
        style: {
          backgroundColor: '#9b2313',
          color:'#c7c7d7',
          fontSize:'14px'
        },
        closeButton: false,
      
      })
    }else{
    navigate('/clinic-support/view/' + clinic.primaryId)
    }
 }
 const handleShow = () => 
 {
  if(roleaccess?.remove===0){  
    toast.error('You do not have permission to access this page', {
      position: "top-right",
      style: {
        backgroundColor: '#9b2313',
        color:'#c7c7d7',
        fontSize:'14px'
      },
      closeButton: false,
    
    })
  }else{
  setShow(true);
  }
 }
 
   const {currentUser}=useAuth();
  const [roleaccess, setroleaccess] = useState<any>([]);
  
  useEffect(() => {
    getCRUD(currentUser?.role,8).then((res: any) => {
      var crudres=res.data?.Data[0];
      setroleaccess(crudres);

    })
  }, [])
  return (
    <><ToastContainer/>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <a  onClick={viewDetails}
          
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG
            path='/media/icons/duotune/general/eye.svg'
            className='svg-icon-3'
          />
        </a>

        {/* <a
          href='#' 
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm' onClick={handleShow}
            
        >
          <KTSVG
            path='/media/icons/duotune/general/gen027.svg'
            className='svg-icon-3'
          />
        </a> */}
      </div>

      {/* Modal */}
      {/* <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title> 
        </Modal.Header>
        <Modal.Header>Are you sure you want to delete this support  ?</Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Not Now
          </Button>
          <Button variant="primary" 
          onClick={deleteItem}
          >
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal> */}
      {/* Modal */}
    </>
  )
}

export { ClinicActionsCell }
