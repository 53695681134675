// @ts-nocheck
import {Column} from 'react-table'
import {ClinicPaymentDate,ClinicPaymentStatus} from './ClientInvoiceInfoCell'
import {ClientInvoiceActionsCell} from './ClientInvoiceActionsCell'
 
import {ClientInvoiceCustomHeader} from './ClientInvoiceCustomHeader'
 
import {List} from '../../core/_models'

const insuranceHolderColumns: ReadonlyArray<Column<List>> = [
  {
    Header: (props) =><ClientInvoiceCustomHeader tableProps={props} title='#' className='' />,
    accessor: 'serialNo',
   
  },
  {
    Header: (props) => (
      <ClientInvoiceCustomHeader tableProps={props} title='Date' className='min-w-125px' />
    ),
    accessor: 'paymentDate',
    Cell: ({...props}) => <ClinicPaymentDate clinicPDate={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ClientInvoiceCustomHeader tableProps={props} title='Reference' className='min-w-125px' />
    ),
    accessor: 'invoiceNumber',
    
  },
 
  {
    Header: (props) => (
      <ClientInvoiceCustomHeader tableProps={props} title='Amount ( RM )' className='min-w-125px' />
    ),
    accessor: 'total',
  },
  
  {
    Header: (props) => (
      <ClientInvoiceCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'status',
    Cell: ({...props}) => <ClinicPaymentStatus clinicPDate={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ClientInvoiceCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ClientInvoiceActionsCell id={props.data[props.row.index].id} cpay={props.data[props.row.index]} />,
  },
]

export {insuranceHolderColumns}
