import {ID, Response} from '../../../../../../../_metronic/helpers'
export type List = {
  id?: ID
  primaryId?: string
   amount?: string
   total?: string
  description?: string
  paymentDate?: string
  invoiceNumber?: string
  Code:string
  Message:string
  
status:string
}

export type ClinicPaymentQueryResponse = Response<Array<List>>

export const initialValue: List = {
  primaryId:'',
  total:'',
  amount: '',
  description: '',
  paymentDate: '',
  invoiceNumber: '',
  Code: '',
  Message: '',
  status:''
}

export type Add = {
  formImage: any
  id?: ID
  total:string
  price:string
  tax:string
  amount: string
  remarks: string
  description: string
  invoiceDueDate: string
  paymentDate: string
  invoiceNumber: string
  clientSentFile?:any | undefined
  addadminSentFile?:any 
  clientId:string
 }
export const initialValues: Add = {
  formImage:'',
  total:'',
  price: '',
  tax: '',
  amount: '',
  description: '',
  paymentDate: '',
  invoiceNumber: '',
  clientSentFile:'',
  invoiceDueDate:'',
  addadminSentFile:'',
  remarks:'',
  clientId:'',
}


export type AddFile = {
  formImage: any
  addadminSentFile?:any 
 }
export const initialValuess: AddFile = {
  formImage:'',
  addadminSentFile:'',
  
  
}