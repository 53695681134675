/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {Claim} from '../../core/_models'
import Avatar from 'react-avatar'
import { fetchData } from '../../core/_requests'
type Props = {
  claim: Claim
}

const ClaimInfoCell: FC<Props> = ({claim}) =>{
  const [patientname,setpatientname]=useState('')
  const empUniqueId=claim.employeeId;
  fetchData(empUniqueId).then((res:any)=>{
  setpatientname(res.data.data[0].name);
  })
return(
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <Avatar size="40"  round="20px" maxInitials={2} name={claim.familymember!==null ? (claim.familymember?.patientName) :(patientname)}></Avatar>
    </div>
    <div className='d-flex flex-column'>
      <a  className='text-gray-800  mb-1'>
      {claim.familymember!==null ? (claim.familymember?.patientName) :(patientname)} <br/>  
      {empUniqueId}
      </a>
      
    </div>
  </div>
)
}

const ClaimInfoCellStatus: FC<Props> = ({claim}) =>
(
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className=''>
    <div className='text-dark fw-bold  fs-6'>
                      {claim?.approvalStatus=="1" ? ( <a className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3' >
                       Approved By Admin
                      </a>):(
                      
                        <a className='btn btn-sm btn-light-warning fw-bolder ms-2 fs-8 py-1 px-3' >
                       Pending
                      </a>
                        )}
                      </div>
       
    </div> 
    
  </div>
)
const ClaimInfoCellCreated: FC<Props> = ({claim}) =>
(
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className=''>
    <div className=' fw-bold  fs-6'>
              
               { new Date(claim?.createdAt).toLocaleDateString("en-GB", { day: "numeric",  month: "short",year: "numeric" })}    
      </div>
       
    </div> 
    
  </div>
)
const ClaimInfoCellEmp: FC<Props> = ({claim}) =>{
  const [patientname,setpatientname]=useState('')
  const empUniqueId=claim.employeeId;
  fetchData(empUniqueId).then((res:any)=>{
  setpatientname(res.data.data[0].name);
  })
return(
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
     {patientname} <br></br>
      {claim.employeeId}
   </div>
)
}
export {ClaimInfoCell,ClaimInfoCellStatus,ClaimInfoCellCreated,ClaimInfoCellEmp}
