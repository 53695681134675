import React, {useEffect, useState} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import axios from 'axios'
import {getToken} from '../../../auth'
import {ids} from 'webpack'
import {PersonalDetails} from './PersonalDetails'
import {GraphPageVisit} from './GraphPageVisit'
import {GraphPageCredit} from './GraphPageCredit'
import {StatisticsWidget} from './StatisticsWidget'
import {HistoryVisitPage} from './HistoryVisitPage'
import {CompanyCountPage} from './CompanyCountPage'
import {getCompanyReport} from '../clint-report-list/_requests'
import {NonpanelVisitPage} from './NonpanelVisitPage'

const CompanyReportOverview = () => {
  const [cmpDetails, setcmpDetails] = useState<any>([])
  const [penel, setpenel] = useState<any>([])
  const [nonpenel, setnonpenel] = useState<any>([])
  const {id} = useParams()
  useEffect(() => {
    getCompanyReport(id).then((res: any) => {
     
      setcmpDetails(res.data.Data)
     
      setpenel(res?.data?.Data?.panelvisits)
      setnonpenel(res?.data?.Data?.nonpanelvisits)
    })
  }, [])

  return (
    <>
      <div className='row g-5 g-xxl-8 col-xl-12' >
        <CompanyCountPage />
        <div className='col-xl-3 custom-bg-color'>
          <div className='row'>
            <StatisticsWidget
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='/media/icons/duotune/graphs/gra007.svg'
              cardColorTop='#F2416D'
              cardColorBottom='#CD375C'
              iconColor='white'
              title={`MYR ${cmpDetails?.payReceived}`}
              description='Total'
              titleColor='#fff'
            />
          </div>
          <div className='row'>
            <PersonalDetails />
          </div>
        </div>

        <div className='col-xl-9' style={{paddingLeft:'3%'}}>
          <GraphPageVisit className='mb-1 mb-xxl-1' />
          <GraphPageCredit className='mb-3 mb-xxl-5' />
        </div>
        
        <div className='col-6'>
    <HistoryVisitPage className='mb-5 mb-xxl-8 col-md-12' data={penel} />
  </div>

  <div className='col-6'>
    <NonpanelVisitPage className='mb-5 mb-xxl-8 col-md-12' data={nonpenel} />
  </div>
      </div>
    </>
  )
}
export default CompanyReportOverview
