/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
// import { getClinicById, updatecontrols } from '../clinic-support-list/core/_requests'
import { ClinicSupport, initialValues } from '../clinic-support-list/core/_models'
import { Modal, Button } from "react-bootstrap";
import Avatar from 'react-avatar'
import { useAuth } from '../../../auth'
import { getCRUD } from '../../companies/company-list/core/_requests'
import { getreplyList, getSupportStatus, updateclinicStatus, updateReply, viewclinicSupport } from '../clinic-support-list/core/_requests'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AlertContext } from '../../../../../_metronic/layout/components/alert-context';

export function ViewClinicSupport() {
 
const {showAlert}=useContext(AlertContext)
  const location = useLocation()
  const { id } = useParams();
  const navigate = useNavigate()


  const { currentUser } = useAuth();
  const [roleaccess, setroleaccess] = useState<any>([]);
  const [sup, setsup] = useState<any>([]);
  const [statuss, setstatuss] = useState<any>([]);
  const [replist, setreplist] = useState<any>([]);
  const [loading, setLoading] = useState(false)
  const [showModal, setShow] = useState(false);
  const [statusId, setstatusId] = useState(null);
  const [statusdesc, setstatusdesc] = useState(null);
  const [reply, setreply] = useState(null);
  const handleClose = () => setShow(false);
  const [showCModal, setCShow] = useState(false);
  const handleCClose = () => setCShow(false);
  const [btnDisable, setbtnDisable] = useState(true)
  const [RbtnDisable, setRbtnDisable] = useState(true)
  useEffect(() => {
    getCRUD(currentUser?.role, 8).then((res: any) => {
      var crudres = res.data?.Data[0];
      setroleaccess(crudres);

    })
  }, [])
  useEffect(() => {
    viewclinicSupport(id).then((res: any) => {
      
      var supcont = res?.Data[0];
      setsup(supcont);

    })
  }, [])
  const handleShow = () => {
    if (roleaccess?.edit === 0) {
      toast.error('You do not have permission to access this page', {
        position: "top-right",
        style: {
          backgroundColor: '#9b2313',
          color: '#c7c7d7',
          fontSize: '14px'
        },
        closeButton: false,

      })
    } else {
      setstatusId(null);
      setShow(true);
    }
  }
  const handleCommandShow = () => {
    if (roleaccess?.edit === 0) {
      toast.error('You do not have permission to access this page', {
        position: "top-right",
        style: {
          backgroundColor: '#9b2313',
          color: '#c7c7d7',
          fontSize: '14px'
        },
        closeButton: false,

      })
    } else {
      setCShow(true);
    }
  }

  const submitchangeStatus = () => {
    setLoading(true);
    updateclinicStatus(statusId, statusdesc, id).then((res) => {
      showAlert(res?.Message,res?.Code);
      navigate('/clinic-support/list', {
        replace: true
      })
    })
    setLoading(false);
    setShow(false);
  }


  useEffect(() => {
    getSupportStatus().then((res: any) => {
      
      var supcont = res.Data;
      setstatuss(supcont);

    })
  }, [])
  const handleStatusChange = (e: any) => {
    const statusId = e.target.value;

    if (statusId != "") {
      
      setstatusId(statusId);
      setbtnDisable(false);
    }
    else {
      
      setstatusId(null);
      setbtnDisable(true);
    }

  }
  const handledescription = (e: any) => {
    const statusDesc = e.target.value;
    setstatusdesc(statusDesc);
  }
  const SubmitReply = () => {
    setLoading(true);
    const data = {
      supportId: id,
      toId: sup?.fromId,
      replymessage: reply
    }
    updateReply(data).then((res) => {
      
      navigate('/clinic-support/view/'+id, { replace: true })
      
      setCShow(false)
      setLoading(false)
      showAlert(res?.Message,res?.Code)
      getreplyList(id).then((res: any) => {
        
        var supcont = res.Data;
        setreplist(supcont);
  
      })
    })
  }
  const handledeReply = (e: any) => {
    if (e.target.value != "") {
      setreply(e.target.value);
      setRbtnDisable(false)
    }
    else {
      setreply(null);
      setRbtnDisable(true)
    }
  }
  useEffect(() => {
    getreplyList(id).then((res: any) => {
      
      var supcont = res.Data;
      setreplist(supcont);

    })
  }, [])
  return (
    <>
      <div className="tab-content" id="myTabContent">

        <div className='mb-xl-10 tab-pane fade active show' id='kt_tab_overview' role="tabpanel">

          <div className='card mb-5 mb-xl-10'   >
            <div className='col-lg-12 card-header cursor-pointer'>
              <div className='col-lg-6 card-title m-0'>
                <h3 className='fw-bolder m-0'>Support Details</h3>
              </div>
              {roleaccess?.edit === 1 ? (
                <>
                  <Button className='col-lg-2 btn btn-primary align-self-center' onClick={handleShow} >
                    Update Status
                  </Button>
                </>
              ) : ("")}

            </div>


            <div className='card-body p-9'>


              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  Date

                </label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>   {new Date(sup?.createdAt).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric"
                  })
                  }</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  Subject

                </label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{sup?.subject}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  Priority

                </label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>

                    <div className='text-dark fw-bold  fs-6'>
                      <a className='btn btn-sm btn-light-warning fw-bolder   fs-8 py-1 px-3' >
                        {sup?.supportpriority?.priorityName}
                      </a>
                    </div>
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  Message

                </label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{sup?.message}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  Status

                </label>

                <div className='col-lg-8'>
                  <div className='text-dark fw-bold  fs-6'>
                    <a className='btn btn-sm btn-light-success fw-bolder   fs-8 py-1 px-3' >
                      {sup?.supportstatus?.statusName}
                    </a>
                  </div>
                </div>
              </div>
              {sup?.statusnotes != null ? (<div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  Status Description

                </label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{sup?.statusnotes}</span>
                </div>
              </div>
              ) : ("")}
            </div>
          </div>
          <div className='card mb-5 mb-xl-10'   >
            <div className='col-lg-12 card-header cursor-pointer'>
              <div className='col-lg-6 card-title m-0'>
                <h3 className='fw-bolder m-0'>Replies to the supports</h3>
              </div>
              {roleaccess?.edit === 1 ? (
                <>
                  <Button className='col-lg-2 btn btn-primary align-self-center' onClick={handleCommandShow}>
                    Make Comment
                  </Button>
                </>
              ) : ("")}

            </div>


            <div className='card-body p-9'>
              {replist.length > 0 ? (
                <div className='row mb-7'>

                  {replist.map((item: any, i: any) => {
                    return (
                      <div className="d-flex flex-row justify-content-start mb-4" key={i}>
                        <Avatar size="40" round="20px"
                          name={item?.fromName} maxInitials={2}
                        />
                        <div className="p-3 me-3 border" style={{ borderRadius: "15px" }}>
                          <h5>{item?.fromName}</h5>
                          <p className="small mb-0">{item?.replymessage}</p>
                        </div>
                      </div>)
                  })}
                </div>) : ("Reply not found")}

            </div>
          </div>
        </div>

      </div>


      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form noValidate className='form'>
            <div className='card-body   p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Status</label>
                <div className='col-lg-8'>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Status' onChange={handleStatusChange}

                  >
                    <option value=''>Select a Status</option>
                    {statuss.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                  {
                    statusId === null && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>Please select status</div>
                      </div>

                    )
                  }
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label   fw-bold fs-6'>Description</label>
                <div className='col-lg-8'>
                  <textarea className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter your status description' onChange={handledescription}

                  />

                </div>
              </div>
            </div>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Not Now
          </Button>
          <Button variant="primary"
            onClick={submitchangeStatus} disabled={btnDisable}
          >
            Update Status
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCModal} onHide={handleCClose}>
        <Modal.Header closeButton>
          <Modal.Title>Reply to the support</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form noValidate className='form'>
            <div className='card-body   p-9'>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Reply </label>
                <div className='col-lg-8'>
                  <textarea className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter reply message'
                    onChange={handledeReply}
                  />
                  {
                    reply === null && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>Please provide your comment</div>
                      </div>

                    )
                  }
                </div>
              </div>
            </div>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCClose}>
            Not Now
          </Button>
          <Button variant="primary"
            onClick={SubmitReply} disabled={RbtnDisable}
          >
            Submit Comment
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
