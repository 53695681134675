/* eslint-disable jsx-a11y/anchor-is-valid */
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import {StatisticsWidget1} from '../../../_metronic/partials/widgets'
 
import {ClientTable} from './ClientTable'
import {Widget} from './Widget'
import {EmployeeTable} from './EmployeeTable'
import React , { FC,useEffect, useState }from 'react'
import { widgetCountClient } from '../core/_request'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import { useAuth } from '../../modules/auth'
import { getCRUD } from '../../modules/apps/companies/company-list/core/_requests'
import { StatisticsWidget3 } from './StatisticsWidget3'


// const usersBreadcrumbs: Array<PageLink> = [
//   {
//     title: 'Dashboard',
//     path: '/dashboard',
//     isSeparator: false,
//     isActive: false,
//   },
//   {
//     title: '',
//     path: '',
//     isSeparator: true,
//     isActive: false,
//   },
// ]

const ClientDashboardWrapper: FC = () => {
  const intl = useIntl()
  //Data Set
  const [data, setData] = useState<any>();
  const [count, setCount] = useState<any>([]);
   const updateData = (fieldsToUpdate: Partial<any>): void => {
  const updatedData = Object.assign(data, fieldsToUpdate)
  setData(updatedData)
  }
  
 
  useEffect(() => {
    widgetCountClient().then((res: any) => {      
    //  debugger
  
      setCount(res.data.data)   
    });
  }, [])
  const {currentUser}=useAuth();
const [clientaccess, setclientaccess] = useState<any>([]);
const [empaccess, setempaccess] = useState<any>([]);

useEffect(() => {
  getCRUD(currentUser?.role,10).then((res: any) => {
    var crudres=res.data?.Data[0];
    setclientaccess(crudres);

  })
}, [])
useEffect(() => {
  getCRUD(currentUser?.role,11).then((res: any) => {
    var crudres=res.data?.Data[0];
    setempaccess(crudres);

  })
}, [])
  return (
    <>
    <div className='card-header  row  '> 
      <div className='border-0 col-xl-5 mb-5 '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Clients Overview</span>
        </h3> <span className='text-muted mt-1 fw-semibold fs-7'>Dashboard</span>
        <span className='text-muted mt-1 fw-semibold fs-7'> - </span>
        <span className='text-muted mt-1 fw-semibold fs-7'>Clients Overview</span>
      
      </div>
      <div className="row mb-5  col-xl-7 col-12 col-sm-12 col-md-12 px-0">
     
     {clientaccess?.add===1 ? (
      <div
      className='px-0 col-xl-4 col-4 col-sm-4 col-md-4 card-toolbar'
      data-bs-toggle='tooltip'
      data-bs-placement='top'
      data-bs-trigger='hover'
      title='Click to view all employee'
    ><Link  style={{float: "right"}}
    to='/companies/add'
    className='btn btn-sm btn-primary'
    // data-bs-toggle='modal'
    // data-bs-target='#kt_modal_invite_friends'
  >
    <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
    New Client 
    </Link>
    </div>
     ):("")} 
     {empaccess?.add===1 ? (
      <div
          className='px-0 col-xl-4  col-4 col-sm-4 col-md-4 card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to view all employee'
        ><Link   style={{display: 'block',marginLeft: '10px'}}
        to='/insurance-holder/add'
        className='btn btn-sm btn-primary'
        // data-bs-toggle='modal'
        // data-bs-target='#kt_modal_invite_friends'
      >
        <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
        New Employee
        </Link>
        </div>
     ) :("")}
        <div
          className='px-0 col-xl-4  col-4 col-sm-4 col-md-4 card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to view monthwise employee count'
        ><Link   style={{display: 'block',marginLeft: '10px'}}
        to='/insurance-holder/Monthly-head-count'
        className='btn btn-sm btn-primary'
        // data-bs-toggle='modal'
        // data-bs-target='#kt_modal_invite_friends'
      >
        <KTSVG path='/media/icons/duotune/communication/com006.svg' className='svg-icon-3' />
       Employee Count
        </Link>
        </div>
      </div>
      </div>
      <>
    {/* begin::Row */}
    
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-3'>
        <StatisticsWidget3
          className='card-xl-stretch mb-xl-8'
          color='danger'
          title='Total Employees'
          description='Total number of employees'
          change={count?.totalEmployeeCount}
        />
      </div>
      <div className='col-xl-3'>
        <StatisticsWidget3
          className='card-xl-stretch mb-xl-8'
          color='success'
          title='Total Companies'
          description='Total number of active companies'
          change={count?.totalClientsCount}
        />
      </div>
      
      

      <div className='col-xl-3'>
        <StatisticsWidget3
          className='card-xl-stretch mb-5 mb-xl-8'
          color='primary'
          title=' Total Invoices Sent'
          description='Total number of invoices sent'
          change={count?.clientSentCount}
        />
      </div>
      <div className='col-xl-3'>
        <StatisticsWidget3
          className='card-xl-stretch mb-5 mb-xl-8'
          color='primary'
          title='Total Payments Received'
          description='Estimated total payments received'
          change={count?.clientReceiveCount}
        />
      </div>
    </div>
    {/* end::Row */}
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-4'>
        <StatisticsWidget1
          className='card-xl-stretch mb-xl-8'
          image='abstract-4.svg'
          title='Welcome to Clients'
          time= {
            new Date().toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric"
              })
            }
          description='Manage and configure clients based<br/>settings here'
        />
      </div>
      <div className='col-xl-8'>

        <Widget className="card-xl-stretch mb-xl-8" />

      </div>

    </div>
    {/* end::Row */}
    <ClientTable className='card-xl-stretch mb-5 mb-xl-8'/>
    <div className='col-xl-12'>
      <EmployeeTable
        className='card-xl-stretch mb-5 mb-xl-8'

      />
      
    </div>

  </>
    </>
  )
}





export { ClientDashboardWrapper }
