import { KTSVG} from '../../../../../../_metronic/helpers'
import { useContext, useEffect, useState } from 'react'
import { AlertContext } from '../../../../../../_metronic/layout/components/alert-context'
import { Link, useParams } from 'react-router-dom'
import { deleteBanner, getBanner } from './core/_requests'
import { Button, Modal } from 'react-bootstrap'
 
const ListPage = ()=>{
  const{showAlert}=useContext(AlertContext);
  const [showModal, setShow] = useState(false);
  const [clickedId, setClickedId] = useState(null);
  const handleClose = () => setShow(false);
  const {id}=useParams();
    const [bannerlist, setbannerlist] = useState<any>([])
    const fetchData = () => {
      getBanner()
        .then((res: any) => {
          setbannerlist(res.Data);
        })
        .catch((error: any) => {
          // Handle error if necessary
        });
    };
    
    useEffect(() => {
      fetchData();
    }, []);

            
  const deleteItem=()  =>  {

deleteBanner(clickedId).then((res: any) => {

  setShow(false);
fetchData();
showAlert(res?.Message,res?.Code);
})
  }
  const handleShow = (id:any) => {
    setClickedId(id);
    setShow(true);
  }
  
  return(
    <>
   <div className="card  card-xl-stretch mb-5 mb-xl-8">
   <div className='card mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'> <span className='card-label fw-bold fs-3 mb-1'>Banner Info</span>
         </h3>
              </div> 
            <Link to='/mobile-settings/banner/add'  className='btn btn-primary align-self-center'>
            Add Banner
          </Link> 
            </div>
            <div className='card-body p-9'>
            
            <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                
                <th className='min-w-150px'>S.No</th>
                <th className='min-w-150px'>Image</th>
                <th className='min-w-140px'>Name</th>
                <th className='min-w-120px'>Title</th>
                <th className='min-w-120px'>Description</th>
                <th className='min-w-100px text-end'>Actions</th> 
              </tr>
            </thead>
            
            <tbody>
          
            {bannerlist.map((item:any,i:any) => {
              var editlink="/mobile-settings/banner/edit/"+item.id;
                return(
              <tr key={i} >
                  <td>  {i+1}</td>
                <td><img src={item.image} style={{width:'100px',height:'80px'}}/> </td>
                <td>{item.name}  </td>
                <td >  {item.title}</td>
                <td>{item.description} </td> 
                <td><div className='d-flex justify-content-end flex-shrink-0'>
                    <Link  to={editlink}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG
                        path='/media/icons/duotune/art/art005.svg'
                        className='svg-icon-3'
                      />
                    </Link>
                     
                    <a
                       onClick={handleShow.bind(null, item.id)}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a>
                  </div></td> 
              </tr>
                )
                 })}
              
            </tbody>
           
          </table>
         
        </div>
           
            </div>
          </div>
     
     
    </div>
    <Modal show={showModal} onHide={handleClose}>
   <Modal.Header closeButton>
     <Modal.Title>Confirmation</Modal.Title>
   </Modal.Header>
   <Modal.Header>Are you sure you want to delete ?</Modal.Header>
   <Modal.Footer>
     <Button variant="secondary" onClick={handleClose}>
       Not Now
     </Button>
     <Button variant="primary" onClick={deleteItem}>
       Yes, Delete
     </Button>
   </Modal.Footer>
 </Modal>
   </>
 
   )
}
 

export {ListPage}
 