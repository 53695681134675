/* eslint-disable jsx-a11y/anchor-is-valid */
import {useContext, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useAuth} from '../../../auth'
import {getClinicById} from '../../clinics/clinic-list/core/_requests'
import {getCRUD} from '../../companies/company-list/core/_requests'
import {AddFile, initialValuess} from '../clinic-payment-list/core/_models'
import {getClinicPaymentById, updateAdminFile} from '../clinic-payment-list/core/_requests'
import fileDownload from 'js-file-download'
import axios from 'axios'

export function Viewform() {
  const {showAlert} = useContext(AlertContext)
  const [data, setData] = useState<AddFile>(initialValuess)
  const [formImage, setFormImage] = useState<File | null>(null)
  const [btnDisable, setbtnDisable] = useState(true)
  const {currentUser} = useAuth()
  //var clinicsID = currentUser?.primaryID;
  const {id} = useParams()
  const [cliPay, setClipay] = useState<any>([])
  const [cli, setCli] = useState<any>([])
  const navigate = useNavigate()
  useEffect(() => {
    getClinicPaymentById(id).then((res: any) => {
      setClipay(res.Data)
      getClinicById(res.Data?.clinicId).then((res: any) => {
        setCli(res.Data)
      })
    })
  }, [])

  useEffect(() => {}, [])

  var msDiff = new Date(cliPay.invoiceDueDate).getTime() - new Date().getTime() //Future date - current date
  var daysTill = Math.floor(msDiff / (1000 * 60 * 60 * 24))
  if (daysTill > 0) {
    var duetilll = `Due in ${daysTill} days`
  } else {
    var duetilll = `Due Date Expired`
  }

  const imageHandler = (e: any) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setData({
          ...data,
          addadminSentFile: reader.result,
        })
      }
    }
    const files = e.target.files

    if (files) {
      setFormImage(files[0])
      reader.readAsDataURL(files[0])
    }
    if (data?.addadminSentFile !== null) {
      setbtnDisable(false)
    }
  }
  const Submitfile = () => {
    setLoading(true)
    setTimeout(() => {
      const formData = new FormData()
      if (formImage) formData.append('addAdminSentFile', formImage)
      updateAdminFile(formData, id).then((res: any) => {
        showAlert(res?.Message, res?.Code)
        navigate('/clinic-payments/list', {replace: true})
      })
      setLoading(false)
    }, 1000)
  }
  const imageHandlerclick = () => {
    // 👇️ open file input box on click of other element
    ;(document.getElementById('fileup') as HTMLElement).click()
  }

  const [loading, setLoading] = useState(false)

  const [roleaccess, setroleaccess] = useState<any>([])

  useEffect(() => {
    getCRUD(currentUser?.role, 5).then((res: any) => {
      var crudres = res.data?.Data[0]
      setroleaccess(crudres)
    })
  }, [])
  const downloadFileAtUrl = (url: any, Inv: any, type: any) => {
    if (type === 1) {
      var Itype = 'Payment'
    } else {
      var Itype = 'Invoice'
    }
    const fileName = url.split('/').pop()
    const fileExtension = fileName.split('.').pop()
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        const DownloadedfileName = Itype + '-' + Inv + '.' + fileExtension
        fileDownload(res.data, DownloadedfileName)
      })
  }
  const viewFileAtUrl = (url: any) => {
    const fileName = url.split('/').pop()

    const fileExtension = fileName.split('.').pop()
    if (fileExtension === 'pdf') {
      axios
        .get(url, {
          responseType: 'arraybuffer',
        })
        .then((res) => {
          const blob = new Blob([res.data], {type: 'application/pdf'})
          const blobUrl = URL.createObjectURL(blob)
          window.open(blobUrl, '_blank')
        })
    } else {
      axios
        .get(url, {
          responseType: 'blob',
        })
        .then((res) => {
          const blobUrl = URL.createObjectURL(res.data)
          window.open(blobUrl, '_blank')
        })
    }
  }
  return (
    <>
      <div className='card'>
        <div className='card-body p-lg-20'>
          <div className='d-flex flex-column flex-xl-row'>
            <div className='flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0'>
              <div className='mt-n1'>
                <div className='d-flex flex-stack pb-10' style={{float: 'right'}}>
                  {roleaccess?.edit === 1 ? (
                    <button
                      type='button'
                      className='btn btn-success'
                      onClick={imageHandlerclick}
                      style={{padding: '12px'}}
                    >
                      <input
                        type='file'
                        id='fileup'
                        style={{display: 'none'}}
                        name='adminUploadfile'
                        onChange={imageHandler}
                      />
                      Upload Payment Proof
                    </button>
                  ) : (
                    ''
                  )}
                </div>
                <div className='m-0'>
                  <div className='fw-bold fs-3 text-gray-800 mb-8'>
                    Invoice #{cliPay?.invoiceNumber}
                  </div>
                  <div className='row g-5 mb-11'>
                    <div className='col-sm-6'>
                      <div className='fw-semibold fs-7 text-gray-600 mb-1'>Issue Date:</div>
                      <div className='fw-bold fs-6 text-gray-800'>
                        {' '}
                        {new Date(cliPay?.paymentDate).toLocaleDateString('en-GB', {
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric',
                        })}{' '}
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='fw-semibold fs-7 text-gray-600 mb-1'>Due Date:</div>
                      <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center flex-wrap'>
                        <span className='pe-2'>
                          {' '}
                          {new Date(cliPay?.invoiceDueDate).toLocaleDateString('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                          })}{' '}
                        </span>
                        {cliPay?.adminPaidDate == null ? (
                          <span className='fs-7 text-danger d-flex align-items-center'>
                            <span className='bullet bullet-dot bg-danger me-2'></span>
                            {duetilll}
                          </span>
                        ) : (
                          <span className='fs-7 text-success d-flex align-items-center'>
                            <span className='bullet bullet-dot bg-success me-2'></span>
                            Paid
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='row g-5 mb-12'>
                    <div className='col-sm-6'>
                      <div className='fw-semibold fs-7 text-gray-600 mb-1'>Issued For:</div>
                      <div className='fw-bold fs-6 text-gray-800'>PCS Rakyat Care.</div>
                      <div className='fw-semibold fs-7 text-gray-600'>
                        79, Jalan SS 21/1a, Damansara Utamae, <br />
                        47400 Petaling Jaya, Selangor, <br />
                        Malaysia.
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='fw-semibold fs-7 text-gray-600 mb-1'>Issued By:</div>
                      <div className='fw-bold fs-6 text-gray-800'>{cli?.name}</div>
                      <div className='fw-semibold fs-7 text-gray-600'>
                        {cli?.address}
                        <br />
                        {cli?.city},{cli?.state}-{cli?.pincode}.
                      </div>
                    </div>
                  </div>
                  <div className='flex-grow-1'>
                    <div className='table-responsive border-bottom mb-9'>
                      <table className='table mb-3'>
                        <thead>
                          <tr className='border-bottom fs-6 fw-bold text-muted'>
                            <th className='min-w-175px pb-2'>Description</th>
                            <th className='min-w-70px text-end pb-2'>Price</th>
                            <th className='min-w-80px text-end pb-2'>Tax </th>
                            <th className='min-w-100px text-end pb-2'>Amount </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className='fw-bold text-gray-700 fs-5'>
                            <td
                              className='d-flex align-items-center pt-6'
                              style={{textAlign: 'justify'}}
                            >
                              <i className='fa fa-genderless text-danger fs-2 me-2'></i>
                              {cliPay?.description}
                            </td>
                            <td className='pt-6'> {cliPay?.amount}</td>
                            <td className='pt-6'> {cliPay?.tax}</td>
                            <td className='pt-6 text-dark fw-bolder'> {cliPay?.total}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='d-flex justify-content-end'>
                      <div className='mw-300px'>
                        <div className='d-flex flex-stack mb-3'>
                          <div className='fw-semibold pe-10 text-gray-600 fs-7'>Subtotal:</div>
                          <div className='text-end fw-bold fs-6 text-gray-800'>
                            RM {cliPay?.total}
                          </div>
                        </div>
                        <div className='d-flex flex-stack mb-3'>
                          <div className='fw-semibold pe-10 text-gray-600 fs-7'>Tax</div>
                          <div className='text-end fw-bold fs-6 text-gray-800'>
                            RM {cliPay?.tax}
                          </div>
                        </div>

                        <div className='d-flex flex-stack'>
                          <div className='fw-semibold pe-10 text-gray-600 fs-7'>Total</div>
                          <div className='text-end fw-bold fs-6 text-gray-800'>
                            RM {cliPay?.total}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='m-0'>
              <div className='d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten'>
                <div className='mb-8'>
                  <h3>Summary</h3>
                </div>
                <h6 className='mb-8 fw-bolder text-gray-600 '>INVOICE DETAILS</h6>
                <div className='row mb-6'>
                  <div className='col-lg-6 fw-semibold text-gray-600 fs-7'>Invoice #:</div>
                  <div className='col-lg-6 fw-bold text-gray-800 fs-6'>{cliPay?.invoiceNumber}</div>
                </div>
                <div className='row mb-6'>
                  <div className='col-lg-6 fw-semibold text-gray-600 fs-7'>Created On:</div>
                  <div className='col-lg-6 fw-bold text-gray-800 fs-6'>
                    {' '}
                    {new Date(cliPay?.createdAt).toLocaleDateString('en-GB', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric',
                    })}
                  </div>
                </div>
                <div className='row mb-6'>
                  <div className='col-lg-6 fw-semibold text-gray-600 fs-7'>Status:</div>
                  <div className='col-lg-6 fw-bold text-gray-800 fs-6'>
                    {cliPay?.status == '2' ? (
                      <a className='btn btn-sm btn-light-danger fw-bolder   fs-8 py-1 px-3'>
                        Invoice Created
                      </a>
                    ) : (
                      <a className='btn btn-sm btn-light-success fw-bolder   fs-8 py-1 px-3'>
                        Payment Submitted
                      </a>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <div className='col-lg-6 fw-semibold text-gray-600 fs-7'>Total:</div>
                  <div className='col-lg-6 fw-bold text-gray-800 fs-6'>RM {cliPay?.total}</div>
                </div>
                <h6 className='mb-8 fw-bolder text-gray-600 '>BANK DETAILS</h6>
                <div className='row mb-6'>
                  <div className='col-lg-6 fw-semibold text-gray-600 fs-7'>Bank Name</div>
                  <div className='col-lg-6 fw-bold fs-6 text-gray-800'>{cli?.bankname}</div>
                </div>
                <div className='row mb-6'>
                  <div className='col-lg-6 fw-semibold text-gray-600 fs-7'>Account Number</div>
                  <div className='col-lg-6 fw-bold text-gray-800 fs-6'>{cli?.account_number}</div>
                </div>
                <div className='row mb-6'>
                  <div className='col-lg-6 fw-semibold text-gray-600 fs-7'>Account Holder</div>
                  <div className='col-lg-6 fw-bold fs-6 text-gray-800 d-flex align-items-center'>
                    {cli?.accountholder_name}
                  </div>
                </div>
                {roleaccess?.edit === 1 ? (
                  <div className='d-flex flex-stack pb-10'>
                    {/* <button  className="btn btn-sm btn-primary" disabled={btnDisable} onClick={Submitfile}>Invoice Paid</button> */}
                    <button
                      onClick={Submitfile}
                      className='btn btn-sm btn-primary'
                      disabled={loading}
                    >
                      {!loading && 'Invoice Paid'}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          {/* Documents */}
          <div className='flex-grow-1 mt-20'>
            <div className='table-responsive border-bottom mb-9'>
              <h3>Documents</h3>
              <table className='table mb-10'>
                <thead>
                  <tr className='border-bottom fs-6 fw-bold text-muted'>
                    <th className='min-w-5px   pb-2'>#</th>
                    <th className='min-w-70px text-end pb-2'>Reference</th>
                    <th className='min-w-80px text-end pb-2'>Document</th>
                    <th className='min-w-100px text-end pb-2'>Date</th>
                    <th className='min-w-100px text-end pb-2'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='fw-bold text-gray-700 fs-5 text-end'>
                    <td className='d-flex align-items-center pt-6'>1</td>
                    <td className='pt-6'> {cliPay?.invoiceNumber}</td>
                    <td className='pt-6'>
                      <a className='btn btn-sm btn-light-success fw-bolder   fs-8 py-1 px-3'>
                        Payment Proof
                      </a>
                    </td>
                    <td className='pt-6 text-dark fw-bolder'>
                      {new Date(cliPay?.paymentDate).toLocaleDateString('en-GB', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      })}
                    </td>
                    <td className='pt-6'>
                      <button
                        className='btn btn-primary btn-sm mx-2'
                        onClick={() =>
                          downloadFileAtUrl(cliPay?.clinicSentFile, cliPay?.invoiceNumber, 1)
                        }
                      >
                        Download
                      </button>
                      <button
                        className='btn btn-info btn-sm'
                        onClick={() => viewFileAtUrl(cliPay?.clinicSentFile)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                  {cliPay?.adminPaidDate != null ? (
                    <tr className='fw-bold text-gray-700 fs-5 text-end'>
                      <td className='d-flex align-items-center pt-6'>2</td>
                      <td className='pt-6'> {cliPay?.invoiceNumber}</td>
                      <td className='pt-6'>
                        <a className='btn btn-sm btn-light-success fw-bolder   fs-8 py-1 px-3'>
                          Invoice Proof
                        </a>
                      </td>
                      <td className='pt-6 text-dark fw-bolder'>
                        {new Date(cliPay?.adminPaidDate).toLocaleDateString('en-GB', {
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric',
                        })}
                      </td>
                      <td className='pt-6'>
                        {cliPay?.adminPaymentFile != '' ? (
                          <button
                            className='btn btn-primary btn-sm mx-2'
                            onClick={() =>
                              downloadFileAtUrl(cliPay?.adminPaymentFile, cliPay?.invoiceNumber, 2)
                            }
                          >
                            Download
                          </button>
                        ) : (
                          'Not Uploaded'
                        )}
                        {cliPay?.adminPaymentFile && (
                          <button
                            className='btn btn-info btn-sm'
                            onClick={() => viewFileAtUrl(cliPay?.adminPaymentFile)}
                          >
                            View
                          </button>
                        )}
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
