import {ClinicReportContent} from './table/CompanyReportContent'
import {KTCard} from '../../../../../_metronic/helpers'

const ClientReport = () => {
  return (
    <>
      <KTCard>
        <ClinicReportContent />
      </KTCard>
    </>
  )
}

const ClientReportWrapper = () => <ClientReport />

export {ClientReportWrapper}
