import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'

import { getToken } from '../../../../auth' 

const API_URL = process.env.REACT_APP_API_URLS
const CLAIM_URL = `${API_URL}/claimform`
const REPORT_URL = `${API_URL}/report`
var AuthVerify = getToken.call(this)

 
const getClinic= async () => {
  const res = await axios.get(`${REPORT_URL}/getClinicdropdown`, getToken())
  return res;
} 
const claimTreatements = async (id:any) => {
  const res = await axios.get(`${CLAIM_URL}/claimTreatements/${id}`, getToken())
 // 
  return res;
}

const getReportforClinic = async (CID:any,date:any,portal:any) => {
  const res = await axios.post(`${REPORT_URL}/getClinicClaimReport/${CID}`,{date,portal}, getToken())
  return res;
} 
export {
  getReportforClinic,getClinic,claimTreatements
}
