/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { getCRUD } from '../../modules/apps/companies/company-list/core/_requests'
import { useAuth } from '../../modules/auth'

type Props = {
  className: string
}

const Widget = ({ className }: Props) =>
{
  const [claimaccess, setclaimaccess] = useState<any>([]);
  const [payaccess, setpayaccess] = useState<any>([]);
  const { currentUser}=useAuth();
  useEffect(() => {
    getCRUD(currentUser?.role,24).then((res: any) => {
      setclaimaccess(res.data?.Data[0]);
      //setTreatment(res.data.Data);

    })
  }, [])
  useEffect(() => {
    getCRUD(currentUser?.role,26).then((res: any) => {
      setpayaccess(res.data?.Data[0]);
      //setTreatment(res.data.Data);

    })
  }, [])

return (
 <div className={`card card-flush ${className}`}>

    <div className="card border-transparent" style={{ backgroundColor: "#1C325E" }} >

      <div className="card-body d-flex ps-xl-15">
      {(claimaccess?.add===1) || (payaccess?.add===1) ? ( 
        <div className="m-0">

          <div className="position-relative fs-2x z-index-2 fw-bold text-white mb-7">
            <span className="me-2">Add or Raise a 
              <span style={{ marginLeft: "15px" }} className="position-relative d-inline-block text-danger">
              <Link to="/portal-claim-form/add" className="text-danger opacity-75-hover"> new Claim Form </Link>

                <span style={{ marginLeft: "15px" }} className="position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100"></span>

              </span > here</span>
            <br />For more support contact us</div>


          <div className="mb-3">
          {claimaccess?.add===1 ? ( <Link to="/portal-claim-form/add"  className="btn btn-danger fw-semibold me-2" >Raise Claim Form</Link>):("")}
          {payaccess?.add===1 ? (  <Link to="/clinic-payment/add"  className="btn btn-color-white bg-white bg-opacity-15 bg-hover-opacity-25 fw-semibold">Raise Payment Request</Link>):("")}
          </div>

        </div>):(
          <div className="m-0">

          <div className="position-relative fs-2x z-index-2 fw-bold text-white mb-7">
            <span className="me-2">Add or Raise a 
              <span style={{ marginLeft: "15px" }} className="position-relative d-inline-block text-danger">
              new Claim Form 

                <span style={{ marginLeft: "15px" }} className="position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100"></span>

              </span > here</span>
            <br />For more support contact us</div>


          <div className="mb-3 position-relative d-inline-block text-danger">
            You Don't have permission to access this page
          </div>

        </div>
        )}


        <img src={toAbsoluteUrl('/media/illustrations/sigma-1/17-dark.png')} className="position-absolute me-3 bottom-0 end-0 h-200px" alt="" />

      </div>

    </div>
  </div>
)
}
export { Widget }
