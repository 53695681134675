import {Route, Routes, Outlet, Navigate, Link} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {ClinicPaymentListWrapper} from './clinic-payment-list/ClinicPaymentList'

import {KTCard} from '../../../../_metronic/helpers'
import { Viewform } from './clinic-payment-form/Viewform'
import { useNavigate } from 'react-router-dom';
import { AlertContext } from '../../../../_metronic/layout/components/alert-context'
import { useContext, useEffect } from 'react'
const cpaymentBreadcrumbs: Array<PageLink> = [
  {
    title: 'Clinic Payments',
    path: '/clinic-payment',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
] 
const AdminClinicPaymentPage = () => {
  const navigate = useNavigate();
  function goBack() {
    navigate(-1);
  }
  const { hideAlert } = useContext(AlertContext);
  useEffect(() => {
    hideAlert(false);
  }, []);
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              
              <PageTitle  breadcrumbs={cpaymentBreadcrumbs} >Clinic Payments </PageTitle>
              <ClinicPaymentListWrapper />
            </>
          }
        />
         <Route
          path='/view/:id'
          element={
            <>
              
              <PageTitle  breadcrumbs={cpaymentBreadcrumbs} >View Invoice </PageTitle>
              <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button>  
              <KTCard>
              <Viewform  />
              </KTCard>
             
            </>
          }
        />
        <Route index element={<Navigate to='list' />} />
      </Route>
    </Routes>
  )
}

export default AdminClinicPaymentPage
