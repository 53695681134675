import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ClientInvoiceHeader} from './components/header/ClientInvoiceHeader'
import {ClientinvoiceTable} from './table/ClientinvoiceTable'
import {KTCard} from '../../../../../../_metronic/helpers'
import { AlertBox } from '../../../../../../_metronic/layout/components/alert-message'
import { useContext} from 'react'
import { AlertContext } from '../../../../../../_metronic/layout/components/alert-context'


const ClientInvoiceList = () => {
 
  const{show,message,type}=useContext(AlertContext);

  return (
    <>
    
      <KTCard>
      {show && <AlertBox type={type == "1" ? 'success' : 'error'} message={message} />}
        <ClientInvoiceHeader />
        <ClientinvoiceTable />
      </KTCard>
    </>
  )
}

const ClientInvoiceListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ClientInvoiceList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ClientInvoiceListWrapper}
