import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ClinicPaymentHeader} from './components/header/ClinicPaymentHeader'
import {ClinicPaymentTable} from './table/ClinicPaymentTable'
import {KTCard} from '../../../../../_metronic/helpers'
import { useLocation } from 'react-router-dom'
import { AlertBox } from '../../../../../_metronic/layout/components/alert-message'
import { useContext, useEffect, useState } from 'react'
import { AlertContext } from '../../../../../_metronic/layout/components/alert-context'


const ClinicPaymentList = () => {
  
  const {show,message,type}=useContext(AlertContext)

  return (
    <>
    {show && <AlertBox type={type=="1" ? 'success' : 'error'} message={message} />}
      <KTCard>
        <ClinicPaymentHeader />
        <ClinicPaymentTable />
      </KTCard>
    </>
  )
}

const ClinicPaymentListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ClinicPaymentList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ClinicPaymentListWrapper}
