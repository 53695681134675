/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { getCRUD } from '../../modules/apps/companies/company-list/core/_requests'
import { useAuth } from '../../modules/auth'

type Props = {
  className: string
}

const Widget = ({ className }: Props) =>{
  const {currentUser}=useAuth();
  const [clinicaccess, setclinicaccess] = useState<any>([]);
  
  useEffect(() => {
    getCRUD(currentUser?.role,39).then((res: any) => {
      var crudres=res.data?.Data[0];
      setclinicaccess(crudres);

    })
  }, [])
 return (
 <div className={`card card-flush ${className}`}>

    <div className="card border-transparent" style={{ backgroundColor: "#1C325E" }} >

      <div className="card-body d-flex ps-xl-15">
      {(clinicaccess?.add===1) || (clinicaccess?.list===1) ? (
        <div className="m-0">

          <div className="position-relative fs-2x z-index-2 fw-bold text-white mb-7">
            <span className="me-2">Add or Check
              <span style={{ marginLeft: "15px" }} className="position-relative d-inline-block text-danger">
              <Link to="/clinics/add" className="text-danger opacity-75-hover"> new clinic </Link>

                <span style={{ marginLeft: "15px" }} className="position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100"></span>

              </span > here</span>
            <br />This is the master clinic datas</div>


          <div className="mb-3">
          {clinicaccess?.add===1 ? (<Link to="/clinics/add"  className="btn btn-danger fw-semibold me-2" >Add new clinic</Link>) : ("")} 
          {clinicaccess?.list===1 ? (<Link to="/clinics/list"  className="btn btn-color-white bg-white bg-opacity-15 bg-hover-opacity-25 fw-semibold">List of clinic</Link>) : ("")} 
          </div>

        </div>):(
         <div className="m-0">

         <div className="position-relative fs-2x z-index-2 fw-bold text-white mb-7">
           <span className="me-2">Add or Check
             <span style={{ marginLeft: "15px" }} className="position-relative d-inline-block text-danger">
             new clinic

               <span style={{ marginLeft: "15px" }} className="position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100"></span>

             </span > here</span>
           <br />This is the master clinic datas</div>


         <div className="position-relative d-inline-block text-danger mb-3">
         You Don't have permission to access this page
          </div>

       </div>
        )}


        <img src={toAbsoluteUrl('/media/illustrations/sigma-1/17-dark.png')} className="position-absolute me-3 bottom-0 end-0 h-200px" alt="" />

      </div>

    </div>
  </div>
)
 }
export { Widget }
