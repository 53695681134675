import React, { useContext, useEffect, useState } from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
 
import { useNavigate } from 'react-router-dom'

import { getCompany, getSubSob } from '../../../insurance-holder/insurance-holder-list/core/_requests'
import { createAssign } from './_requests'
import { Button, Modal } from 'react-bootstrap'
import { useAuth } from '../../../../auth'
import { getCRUD } from '../../../companies/company-list/core/_requests'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AlertContext } from '../../../../../../_metronic/layout/components/alert-context'
export interface IProfileDetails {
  planId: string
  clientId: string

}

export let initialValues: IProfileDetails = {
  planId: '', clientId: ''
}
let sobDetailsSchema = Yup.object({
  planId: Yup.string().required("Sub SOB is required"),
  clientId: Yup.string()
    .required('At least one company must be selected')
    .min(1, 'At least one company must be selected')
})
const AssignSubSobContent: React.FC = () => {

  const {showAlert}=useContext(AlertContext)
  const [btnDisable, setbtnDisable] = useState(true)
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [subsob, setSubSob] = useState<any>([])
  const [company, setCompany] = useState<any>([])
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate()
  useEffect(() => {
    getSubSob().then((res: any) => {
      setSubSob(res.data.data)
    })
  }, [])
  useEffect(() => {
    getCompany().then((res: any) => {
      setCompany(res.data.data)
    })
  }, [])
  useEffect(() => {
    if (formik.values.clientId != "" && formik.values.planId != "") {
      setbtnDisable(false);
    }
  })

  const { currentUser } = useAuth();
  const [roleaccess, setroleaccess] = useState<any>([]);
  useEffect(() => {
    getCRUD(currentUser?.role, 18).then((res: any) => {
      var crudres = res.data?.Data[0];
      setroleaccess(crudres);

    })
  }, [])
  
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: sobDetailsSchema,
    onSubmit: (values) => {

    },
  })
  const handleShow = () => {
    if (roleaccess?.add === 0) {
      toast.error('You do not have permission to access this page', {
        position: "top-right",
        style: {
          backgroundColor: '#9b2313',
          color: '#c7c7d7',
          fontSize: '14px'
        },
        closeButton: false,

      })
    } else {
      setShow(true);
    }

  }

  const handleSubmitButton = () => {
    createAssign(formik.values).then((res) => {
      showAlert(res?.Message,res?.Code);
      navigate('/planInfo/list', {replace: true });
    }
    )
    setShow(false);
  };

  return (
    <><ToastContainer />
      <div className='row'>

        <div className='col-lg-5'>
          <div className='card mb-5 mb-xl-10'>

            <div id='kt_account_deactivate'>
              <div className='card-body  p-20'>

                <div className='row'>
                  <h3 style={{ padding: "5px", textAlign: "center" }}>SUB SOB</h3>
                  <span className='text-gray-400 mt-1 fw-semibold fs-6 mb-10'>Please select SUB SOB to assign with clients</span>
                  <div className='col-lg-12 fv-row'>
                    <select
                      className='form-control form-control-lg form-control-solid  mb-3 mb-lg-0'
                      {...formik.getFieldProps('planId')}
                    >
                      <option>Select Sub SOB</option>
                      {subsob?.map((item: any) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        )
                      })}
                    </select>
                    {formik.touched.planId && formik.errors?.planId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.planId}</div>
                      </div>
                    )}
                  </div>


                </div>
              </div>
            </div>

          </div>
        </div>
        <div className='col-lg-1'>
          <div className='mb-5 mb-xl-10'>

            <div id='kt_account_deactivate'>
              <div className=''>

                <div className='row'>
                  <h3 style={{ padding: "5px", textAlign: "center" }}></h3>
                  <span className='text-gray-400 mt-1 fw-semibold fs-6 mb-20'></span>
                  <div className='col-lg-12 fv-row mt-10'>

                    <KTSVG
                      path=' /media/icons/duotune/arrows/arr001.svg'
                      className='svg-icon-2tx svg-icon-primary me-4'
                    />
                  </div>


                </div>
              </div>
            </div>

          </div>
        </div>
        <div className='col-lg-6'>
          <div className='card mb-5 mb-xl-10'>

            <div id='kt_account_deactivate'>
              <div className='card-body  p-20'>

                <div className='row'>
                  <h3 style={{ padding: "5px", textAlign: "center" }}>Company Details</h3>
                  <span className='text-gray-400 mt-1 fw-semibold fs-6 mb-10'>Please select company to assign with SUB SOB</span>
                  <div className='col-lg-12 fv-row'>
                    <select
                      className='form-control form-control-lg form-control-solid  mb-3 mb-lg-0'
                      {...formik.getFieldProps('clientId')}
                    >
                      <option>Select Company</option>
                      {company?.map((item: any) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        )
                      })}
                    </select>
                    {formik.touched.clientId && formik.errors?.clientId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.clientId}</div>
                      </div>
                    )}
                  </div>


                </div>
              </div>
            </div>

          </div>
        </div>



        <div className='card-footer d-flex justify-content-start py-6 px-9'>
          <div className='col-lg-12' style={{ textAlign: 'center' }}>

            <button onClick={handleShow} className='btn btn-primary' disabled={btnDisable}>
              Assign Sub SOB
            </button>
          </div>
        </div>
      </div>


      <Modal show={showModal} onHide={handleClose}>

        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Header>Are you sure, you want assign this sub sob to this company ?</Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Not Now
          </Button>
          <Button variant="primary" onClick={handleSubmitButton}>
            Confirm

          </Button>
        </Modal.Footer>

      </Modal>


    </>


  )

}


export default AssignSubSobContent
