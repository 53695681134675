import {useListView} from '../../core/ListViewProvider'
import {ClaimListToolbar} from './ClaimListToolbar'
import {ClinicListGrouping} from './ClinicListGrouping'
import {ClinicListSearchComponent} from './ClinicListSearchComponent'

const ClinicListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      {/* <ClinicListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ClinicListGrouping /> : <ClaimListToolbar />}
        {/* end::Group actions */}
        {/* <h5>Non panel company report</h5> */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ClinicListHeader}
