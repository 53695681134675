import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import { PlanListWrapper} from './plan-list/RoleList'
import { useNavigate } from 'react-router-dom'; 
import { AlertContext } from '../../../../../_metronic/layout/components/alert-context';
import { useContext, useEffect } from 'react';
import { EditPlanWrapper } from './plan-form/EditPlanWrapper';
import { EditPlanInfoWrapper } from './plan-form/EditPlanInfoWrapper';
import AddBanner from '../banner/banner-form/AddBanner';
 
 

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Mobile Settings list',
    path: '/mobile-settings',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PlanPage = () => {
  const navigate = useNavigate();
  function goBack() {
     
    navigate(-1);
  }
  const { hideAlert } = useContext(AlertContext);
  useEffect(() => {
    hideAlert(false);
  }, []);
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Mobile Settings list</PageTitle>
              
              <PlanListWrapper />
            </>
          }
        />
        <Route
          path='/banner/add'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Mobile Settings list</PageTitle>
              <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button> 
              <AddBanner />
            </>
          }
        />
        <Route
          path='/banner/editcontact'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Mobile Settings list</PageTitle>
              <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button> 
              <EditPlanWrapper />
            </>
          }
        />
        <Route
          path='/banner/editplan'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Mobile Settings list</PageTitle>
              <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button> 
              <EditPlanInfoWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />
    </Routes>
  )
}

export default PlanPage
