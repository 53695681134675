import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
 
 
import {KTCardBody, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import { Link, useNavigate } from 'react-router-dom'

const AccessDeniedContent = () => {
  const navigate = useNavigate();
  function goBack() {
     
    navigate(-1);
  }
   
  return (
    <>
    
     <div className="col-lg-12 pt-20 pb-20" style={{textAlign:'center'}} >
 
{/* begin::Illustration */}
<div className='mb-3'>
  <img
    src={toAbsoluteUrl('/media/illustrations/sketchy-1/5.png')}
    className='mw-100 mh-300px theme-light-show'
    alt=''
  />
  <img
    src={toAbsoluteUrl('/media/illustrations/sketchy-1/5-dark.png')}
    className='mw-100 mh-300px theme-dark-show'
    alt=''
  />
</div>
{/* end::Illustration */}
{/* begin::Title */}
<h1 className='fw-bolder fs-1hx text-gray-900 mb-4'>Access Denied!</h1>
{/* end::Title */}

{/* begin::Text */}
<div className='fw-semibold fs-6 text-gray-500 mb-7'>You don't have permission to access this page.</div>
{/* end::Text */}

{/* begin::Link */}
<div className='mb-0'>
  
 
   
</div>
{/* end::Link */}
</div>
    </>
       
       
  )
}

export {AccessDeniedContent}
