import { useContext, useEffect, useMemo, useState } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'


import { KTCardBody, toAbsoluteUrl } from '../../../../../../../_metronic/helpers'
import { Link, useNavigate } from 'react-router-dom'
import { createroleaccess, getmodules, getRole, getroleaccess } from '../core/_requests'
import { useAuth } from '../../../../../auth'
import { AlertContext } from '../../../../../../../_metronic/layout/components/alert-context'

const RoleaccessContent = () => {
  const {showAlert}=useContext(AlertContext)
  const [btnDisable, setbtnDisable] = useState(true)
  const {currentUser}=useAuth();
  const [role, setRole] = useState<any>([])
  const [module, setModule] = useState<any>([])
  const [roleid, setroleid] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    getRole(currentUser?.primaryID).then((res: any) => {
      setRole(res.data.Data)
    })
  }, [])
  useEffect(() => {
    getmodules().then((res: any) => {
       //
      const updatedModule = res.data.Data.map((obj: any) => ({ ...obj, add: false, list: false, view: false, edit: false, remove: false }));
      
      setModule(updatedModule)
    })
  }, [])
  useEffect(() => {
    const anyChecked = module.some((checkbox: any) => checkbox.add);
    const listChecked = module.some((checkbox: any) => checkbox.list);
    const viewChecked = module.some((checkbox: any) => checkbox.view);
    const editChecked = module.some((checkbox: any) => checkbox.edit);
    const deleteChecked = module.some((checkbox: any) => checkbox.remove);
    if ((anyChecked || listChecked || viewChecked || editChecked || deleteChecked) && roleid != "") {
      setbtnDisable(false)
    }
  })
  // 
  const handleSelectAll = (column: string) => {
    setModule(
      module.map((module: any) => ({
        ...module,
        [column]: !module[column]
      }))
    );
    callValidator()

  }
  const callValidator = () => {
    const anyChecked = module.some((checkbox: any) => checkbox.add);
    const listChecked = module.some((checkbox: any) => checkbox.list);
    const viewChecked = module.some((checkbox: any) => checkbox.view);
    const editChecked = module.some((checkbox: any) => checkbox.edit);
    const deleteChecked = module.some((checkbox: any) => checkbox.remove);
    if (anyChecked == true || listChecked == true || viewChecked == true || editChecked == true || deleteChecked == true) {
      var checkeffect = false;
    } else {
      var checkeffect = true;
    }
    checkvalidation(roleid, checkeffect)
  }
  const handlerole = (e: any) => {
    callValidator()
    setroleid(e.target.value);
    getroleaccess(e.target.value).then((res)=>{

      if(res.data.Data.length>0){
    setModule(res.data.Data);
      }
      else{
        getmodules().then((res: any) => {
          const updatedModule = res.data.Data.map((obj: any) => ({ ...obj, add: false, list: false, view: false, edit: false, remove: false }));
          
          setModule(updatedModule)
        })
      }

    })
  }
  const handleCheckboxChange = (id: any, column: any) => {
   const updatedModules = module.map((module:any) => {
      if (module.id === id) {
        return {
          ...module,
          [column]: !module[column]
        };
      }
      return module;
    });
    setModule(updatedModules)
    const anyChecked = updatedModules.some((checkbox: any) => checkbox.add);
    const listChecked = updatedModules.some((checkbox: any) => checkbox.list);
    const viewChecked = updatedModules.some((checkbox: any) => checkbox.view);
    const editChecked = updatedModules.some((checkbox: any) => checkbox.edit);
    const deleteChecked = updatedModules.some((checkbox: any) => checkbox.remove);
    if (anyChecked == true || listChecked == true || viewChecked == true || editChecked == true || deleteChecked == true) {
      var checkeffect = true;
    } else {
      var checkeffect = false;
    }
    checkvalidation(roleid, checkeffect)
  }
  function checkvalidation(roleid: any, checkeffect: any) {
   // 
    if (roleid != '' && checkeffect != false) {
      setbtnDisable(false)
    }
    else {
      setbtnDisable(true)
    }
  }
  const handleSubmit = () => {
  //  
    const SubmitupdatedModule = module.map((obj: any) => ({ ...obj, moduleId: obj.id, status: "1", roleId: roleid }));
  
    function findObjectsWithValue1(SubmitupdatedModule:any) {
      const result = [];
      for (let i = 0; i < SubmitupdatedModule.length; i++) {
        const obj = SubmitupdatedModule[i];
        for (let prop in obj) {
          if ((obj['add'] === 1 || obj['add'] === true) ||  (obj['list'] === 1 || obj['list'] === true) || (obj['view'] === 1 || obj['view'] === true) || (obj['edit'] === 1 || obj['edit'] === true) || (obj['remove'] === 1 || obj['remove'] === true) ) {
            result.push(obj);
            break;
          }
        }
      }
      return result;
    }

    const objectsToInsert = findObjectsWithValue1(SubmitupdatedModule);
    const updatedResponse = objectsToInsert.map((item: any) => {
      const { name, icon, sbtitle, link, id, ...rest } = item;
      return rest;
    });
   // 
    
    createroleaccess(updatedResponse).then((res:any) => {
      
      showAlert(res?.data.Message,res?.data.Code);
      navigate('/client-role-permission');
    })
   
    
  }
 //
  return (
    <div className='card '>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 pb-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Role & Permissions</span>

        </h3>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='row mb-10'>
          <div className='col-lg-2'>
            <label className=' col-form-label  fw-bold fs-6'>Select Role</label>
            <select className='form-select form-select-solid form-select-lg fw-bold' onChange={handlerole} >
              <option value=''>All </option>
              {role?.map((item: any) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                )
              })}

            </select>
          </div>

        </div>


        <div className='table-responsive' >
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4' >
            {/* begin::Table head */}
            <thead>


              <tr className='fw-bold text-muted'>
                <th className='min-w-50px'>   NO</th>
                <th className='min-w-340px'>	MODULE NAME</th>
                <th className='min-w-100px'><input className='form-check-input' type='checkbox' onChange={() => handleSelectAll("add")} style={{ marginRight: "10px" }} /> ADD</th>
                <th className='min-w-100px'><input className='form-check-input' type='checkbox' onChange={() => handleSelectAll("list")} style={{ marginRight: "10px" }} /> LIST</th>
                <th className='min-w-100px'><input className='form-check-input' type='checkbox' onChange={() => handleSelectAll("view")} style={{ marginRight: "10px" }} /> VIEW</th>
                <th className='min-w-100px'> <input className='form-check-input' type='checkbox' onChange={() => handleSelectAll("edit")} style={{ marginRight: "10px" }} />EDIT</th>
                <th className='min-w-100px'> <input className='form-check-input' type='checkbox' onChange={() => handleSelectAll("remove")} style={{ marginRight: "10px" }} />DELETE</th>
              </tr>

            </thead>

            <tbody>

              {module.map((item: any, i: any) => {
                return (
                  <tr className='fw-bold text-muted' key={i}>
                    <td className='min-w-50px'> {i + 1}</td>
                    <td className='min-w-340px'>	{item.name}</td>
                    <td className='min-w-100px'>	     <input
                      checked={item.add}
                      onChange={() => handleCheckboxChange(item.id, "add")}
                      className='form-check-input'
                      type='checkbox'
                      value={item.id}
                    /></td>
                    <td className='min-w-100px'>	     <input
                      checked={item.list}
                      onChange={() => handleCheckboxChange(item.id, "list")}
                      className='form-check-input'
                      type='checkbox'
                      value={item.id}
                    /></td>
                    <td className='min-w-100px'>	     <input
                      checked={item.view}
                      onChange={() => handleCheckboxChange(item.id, "view")}
                      className='form-check-input'
                      type='checkbox'
                      value={item.id}
                    /></td>
                    <td className='min-w-100px'>	     <input
                      checked={item.edit}
                      onChange={() => handleCheckboxChange(item.id, "edit")}
                      className='form-check-input'
                      type='checkbox'
                      value={item.id}
                    /></td>
                    <td className='min-w-100px'>	      <input
                      checked={item.remove}
                      onChange={() => handleCheckboxChange(item.id, "remove")}
                      className='form-check-input'
                      type='checkbox'
                      value={item.id}
                    /></td>
                  </tr>)
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' onClick={handleSubmit} className='btn btn-primary' disabled={btnDisable}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { RoleaccessContent }
