 
import {ClientInvoiceListWrapper} from '../client-invoice-list/ClientInvoiceList'
import {KTCard} from '../../../../../../_metronic/helpers'

const ClinicPayments = () => {
 
  return (
    <>
      <KTCard>
     
        <ClientInvoiceListWrapper />
      </KTCard>
    </>
  )
}

const ClientInvoiceWrapper = () => (
  
        <ClinicPayments />
      
)

export {ClientInvoiceWrapper}
