import { useEffect, useState } from 'react'
import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import { useAuth } from '../../../../../auth'
import { getCRUD } from '../../../../companies/company-list/core/_requests'
import {useListView} from '../../core/ListViewProvider'
import {ClinicSupportListFilter} from './ClinicSupportListFilter'

const ClinicListToolbar = () => {
  const navigate = useNavigate()
  
  const {currentUser}=useAuth();
  const [roleaccess, setroleaccess] = useState<any>([]);
  
  useEffect(() => {
    getCRUD(currentUser?.role,8).then((res: any) => {
      var crudres=res.data?.Data[0];
      setroleaccess(crudres);

    })
  }, [])

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
     <ClinicSupportListFilter/>
    </div>
  )
}

export {ClinicListToolbar}
