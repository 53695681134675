import {Route, Routes, Outlet, Navigate, Link, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {RoleaccessWrapper} from './role-setting-list/RoleaccessWrapper'

import {KTCard, KTSVG} from '../../../../../_metronic/helpers'
import { AlertContext } from '../../../../../_metronic/layout/components/alert-context'
import { useContext, useEffect } from 'react'
import { DashboardWrapper } from './Overview/DashboardWrapper'
 
const cpaymentBreadcrumbs: Array<PageLink> = [
  {
    title: 'Role Access',
    path: '/role-access',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
] 
const ClientRoleaccessPage = () => {
  const { hideAlert } = useContext(AlertContext);
  useEffect(() => {
    hideAlert(false);
  }, []);
  const navigate = useNavigate();
 function goBack() {
   navigate(-1);
 }
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              
              <PageTitle  breadcrumbs={cpaymentBreadcrumbs} >Role Access </PageTitle>
              <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button> 
              <div className='notice d-flex bg-light-success rounded border-success border border-dashed mb-10 p-6'>
                <KTSVG
                  path='/media/icons/duotune/general/gen048.svg'
                  className='svg-icon-2tx svg-icon-success me-4'
                />
                <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                  <div className='mb-3 mb-md-0 fw-bold'>
                    <h4 className='text-gray-800 fw-bolder'>Roles & Permissions</h4>
                    <div className='fs-6 text-gray-600  pe-7' >
                      Step 1: You can add Roles here.
                    </div>
                    <div className='fs-6 text-gray-600 pe-7' >
                      Step 2: Create user for this role and create new username & password for this role here
                    </div>
                    <div className='fs-6  pe-7' style={{color: "#ffc700"}}>
                      Step 3: Create roles & permission for this role here.
                    </div>
                  </div>
                  
                </div>
              </div> 
              <RoleaccessWrapper />
            </>
          }
        />
         <Route
          path='/overview'
          element={
            <>
              <DashboardWrapper />
            </>
          }
        />
        <Route index element={<Navigate to='overview' />} />
      </Route>
    </Routes>
  )
}

export default ClientRoleaccessPage
