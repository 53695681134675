 
 
import {RoleaccessContent} from './table/RoleaccessContent'
import {KTCard} from '../../../../../../_metronic/helpers'
import { useContext } from 'react'
import { AlertContext } from '../../../../../../_metronic/layout/components/alert-context'
import { AlertBox } from '../../../../../../_metronic/layout/components/alert-message'

const Roleaccess = () => {
  const {show,message,type}=useContext(AlertContext)
  return (
    <>
      {show && <AlertBox type={type == "1" ? 'success' : 'error'} message={message} />}
      <KTCard>
      
        <RoleaccessContent />
      </KTCard>
    </>
  )
}

const RoleaccessWrapper = () => (
  
        <Roleaccess />
      
)

export {RoleaccessWrapper}
