import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
 
import {getToken} from '../../../../auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS
const PLAN_URL = `${API_URL}/plan`

var AuthVerify = getToken.call(this)




const createAssign = (data: any) => {

  return axios
    .post(`${PLAN_URL}/assignPlan/addAssignedPlan`, {data},getToken())
   .then((response) => response.data)
}
const getClientByPlanId = (id: any) => {
   
  return axios
    .get(`${PLAN_URL}/viewassignPlan/${id}`,getToken())
   .then((response) => {
   
    return response.data});
}
export {

  createAssign,getClientByPlanId
 
 
}
