import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../../_metronic/helpers'
import { List, ClinicPaymentQueryResponse, initialValue } from './_models'
import { getToken } from '../../../../auth/core/AuthHelpers'
import { useAuth } from '../../../../auth'

const API_URL = process.env.REACT_APP_API_URLS
const MAIN_URL = `${API_URL}/clinicPayment`
var AuthVerify = getToken.call(this);



const getClinicPayment = (query: string): Promise<ClinicPaymentQueryResponse> => {
  return axios
    .get(`${MAIN_URL}/query/?${query}`, getToken())
    .then((d: AxiosResponse<ClinicPaymentQueryResponse>) => {
  
      return d.data
    })
}
const getClinicPaymentById = (id:any) => {
  return axios
    .get(`${MAIN_URL}/viewClinicPayment/${id}`,getToken()) 
    .then((response) =>{
   
      return response.data;
    }
   )
}
const addClinicPayment = (data: FormData,clinicID:any) => {
 
  return axios
  .post(`${MAIN_URL}/addClinicPayment/${clinicID}`, data,getToken())
  .then((response) => {
 
    return response.data
   })
   
}
const updateAdminFile=(data: FormData,id:any)=>{

  return axios
  .put(`${MAIN_URL}/UpdateAdminPaid/${id}`, data,getToken())
  .then((response) => {
   
    return response.data
   })
}
export {
  getClinicPayment,addClinicPayment,getClinicPaymentById,updateAdminFile
}
