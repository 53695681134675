/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {dateFormat} from '../../../auth/core/AuthHelpers'
import {Link, useParams} from 'react-router-dom'

type Props = {
  className: string
  data: any
}
const divider = {
  display: 'inline-block',
  width: '20px',
  backgroundColor: 'black', // Use camelCase for CSS property names
  // margin: '0 10px',
  height: '100%', // Make sure to wrap '1em' in quotes
}

const HistoryVisitPage: React.FC<Props> = ({className, data}) => {
  const {id} = useParams()
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Recent Visits</h3>
        {/* <div className='card-toolbar'>
         
          <Link to={`/claim-form`}>View All</Link>
         
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        {/* begin::Item */}
        {data.map((item: any, i: any) => {
          return (
            <div className='d-flex  mb-3' key={i} style={{marginTop: '0%'}}>
              {/* begin::Avatar */}
              <div className='col-md-4'>
                {/* <img src={toAbsoluteUrl('/media/icons/duotune/general/gen025.svg')} alt='' /> */}
                <div className='text-dark fw-bold  fs-6'>{item?.fromName}</div>
                <div className='text-muted d-block fw-semibold'>{item?.uniqueId}</div>
              </div>

              <div className='timeline-badge' style={{marginTop: '0%'}}>
                <i className='fa fa-genderless text-warning fs-1'></i>
              </div>

              <div className='flex-grow-1 col-md-6' style={{marginLeft: '3%', marginTop: '0%'}}>
                <div className='text-dark fw-bold  fs-6'>
                  {item?.treatmentdetails ? item?.treatmentdetails[0]?.treatment?.name : ''}
                </div>
                <span className='text-muted d-block fw-semibold'>
                  Visited on {dateFormat(item?.visitDate)}
                </span>
              </div>

              <div className='d-flex justify-content-center flex-shrink-0 col-md-2'>
                <a
                  href={`/claim-form/view/${item?.id}`}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                >
                  <KTSVG path='/media/icons/duotune/general/eye.svg' className='svg-icon-3' />
                </a>
              </div>
            </div>
          )
        })}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {HistoryVisitPage}
