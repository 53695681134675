/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {Claim} from '../../core/_models'
import Avatar from 'react-avatar'
import {FetchNonclaimholders} from '../../core/_requests'
type Props = {
  claim: any
}

const ClaimInfoCell: FC<Props> = ({claim}) => {
  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <Avatar
          size='40'
          round='20px'
          name={claim?.insuranceholder?.patientname}
          maxInitials={2}
        ></Avatar>
      </div>
      {claim?.insuranceholder?.patientname}
    </div>
  )
}
const ClinicPaymentDate: FC<Props> = ({claim}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className=''>
      {new Date(claim?.visitDate).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })}
    </div>
  </div>
)
const Claimapprovalstatus: FC<Props> = ({claim}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className=''>
      <div className='text-dark fw-bold  fs-6'>
        {claim?.approvalStatus == '1' ? (
          <a className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'>
            Approved By Admin
          </a>
        ) : claim?.approvalStatus == '2' ? (
          <a className='btn btn-sm btn-light-danger fw-bolder ms-2 fs-8 py-1 px-3'>Rejected</a>
        ) : (
          <a className='btn btn-sm btn-light-warning fw-bolder ms-2 fs-8 py-1 px-3'>Pending</a>
        )}
      </div>
    </div>
  </div>
)
export {ClaimInfoCell, ClinicPaymentDate, Claimapprovalstatus}
