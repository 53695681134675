import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {NonpanelListFilter} from './NonpanelListFilter'

const ClaimListToolbar = () => {
  const navigate = useNavigate()
  const openAddUserModal = () => {
    navigate('/NonpanelReport/list')
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <NonpanelListFilter/>
        <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
         <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-2' />
        Export Data
       </button> */}
    </div>
  )
}

export {ClaimListToolbar}
