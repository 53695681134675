import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
 
import DatePicker from "react-datepicker";
import { getSupportStatus } from '../../core/_requests'
import { getpriority } from '../../../../clinic-portal/clinic-support/clinic-support-list/core/_requests'
const ClinicSupportListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [dateRange, setDateRange] = useState([null, null]);
  const [priority, setpriority] = useState('')
  const [status, setstatus] = useState('')
  const [statuslist, setStatusList] = useState([])
  const [priorityList, setPriorityList] = useState([])
  const [startDate, endDate] = dateRange;
  useEffect(() => {
    getSupportStatus().then((res: any) => {
      //
     setStatusList(res.Data)
    })
  }, [])
  useEffect(() => {
    getpriority().then((res: any) => {
      setPriorityList(res.data.Data)
    })
  }, [])
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
    setDateRange([null, null]);
    setpriority('');
    setstatus('');
  }

  const filterData = () => {
    var Daterange= (document.getElementById('Daterange') as HTMLInputElement).value;
    updateState({
      filter: {priority, status,Daterange},
      ...initialQueryState,
    })
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
           {/* begin::Input group */}
           <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Date Range :</label>
            <DatePicker className='form-control form-control-lg form-control-solid'  id="Daterange" 
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                   
                    onChange={(update:any,e:any) => {
                      setDateRange(update);
                       e.stopPropagation();
                    }}
                    isClearable={true}
                    dateFormat="dd/MM/yyyy"
                  />
          </div>
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Support Priority :</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setpriority(e.target.value)}
              value={priority}
            >
              <option value=''>All</option>
              
              {priorityList.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id} >
                          {item.name}
                        </option>
                      )
                    })} 
            </select>
          </div>
          {/* end::Input group */}
<div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Support Status :</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setstatus(e.target.value)}
              value={status}
            >
              <option value=''>All</option>
              {statuslist.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id} >
                          {item.name}
                        </option>
                      )
                    })} 
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData} 
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
               onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {ClinicSupportListFilter}
