import React, {useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {FormikHelpers, useFormik} from 'formik'
 
import {ClinicSupport, initialValues} from '../client-support-list/core/_models'
import {useNavigate} from 'react-router-dom'
import { createClientSupport, getpriority } from '../client-support-list/core/_requests'
import { useAuth } from '../../../../auth'
import { AlertContext } from '../../../../../../_metronic/layout/components/alert-context'
 

interface Event<T = EventTarget> { 
  target: T
  // ...
}

const clinicDetailsSchema = Yup.object().shape({
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required'),
  priority: Yup.string().required('Priority is required'),
})

const ClinicDetails: React.FC = () => {
  const{showAlert}=useContext(AlertContext);

  const navigate = useNavigate()
   const {currentUser}=useAuth();
  const [priority, setpriority] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const formik = useFormik<ClinicSupport>({
    initialValues, 
    validationSchema: clinicDetailsSchema,
    onSubmit: (values,actions) => {
      actions.resetForm({
             values: initialValues})
      
      setLoading(true)
      setTimeout(() => {
        values.fromId=currentUser?.primaryID;
        createClientSupport(values).then((res) => {
          showAlert(res?.Message,res?.Code);
          navigate('/portal-client-support/list',{replace: true })
          
        })
        
        setLoading(false)
        
      }, 1000)
     
    },
      
  }) 
  
  useEffect(() => {
    getpriority().then((res: any) => {
      setpriority(res.data.Data)
    })
  }, [])
  
  return (
    <>
    
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Add Support</h3>
          </div>
        </div>
        <div id='kt_account_profile_details'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              {/** Clinic Info */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Subject</label>
                <div className='col-lg-8'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter support subject' style={{textTransform: "capitalize" }}
                    {...formik.getFieldProps('subject')}
                  />
                  {formik.touched.subject && formik.errors.subject && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.subject}</div>
                    </div>
                  )}
                </div>
              </div>
              
              {/** Country */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Type of Priority</label>
                <div className='col-lg-8'>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' '
                    {...formik.getFieldProps('priority')}
                  >
                    <option value=''>Select a Priority</option>
                    {priority.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                  {formik.touched.priority && formik.errors.priority && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.priority}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Address fields */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Message</label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <textarea
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Enter support message'
                        {...formik.getFieldProps('message')}
                      />
                      {formik.touched.message && formik.errors.message && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.message}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
             </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button onSubmit={() => formik.resetForm()} type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default ClinicDetails
 

