import {Route, Routes, Outlet, Navigate, Link} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {ClinicReportWrapper} from './clinic-report-list/ClinicReportWrapper'
import {KTCard} from '../../../../../_metronic/helpers'

const creportBreadcrumbs: Array<PageLink> = [
  {
    title: 'Clinic Reports',
    path: '/portal-clinic-report',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
] 
const ClincReportPortalPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
                <PageTitle  breadcrumbs={creportBreadcrumbs} >Clinic Report </PageTitle>
               
              <ClinicReportWrapper />
            </>
          }
        />
        
        <Route index element={<Navigate to='list' />} />
      </Route>
    </Routes>
  )
}

export default ClincReportPortalPage
