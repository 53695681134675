import React, {useEffect, useRef, useState} from 'react'
import {
  getCompany,
  getEmployee,
  getClinic,
  getTreatement,
  getReportforCompany,
  getPlan,
  getReportforCompanyNotIn,
} from '../_requests'
import DatePicker from 'react-datepicker'
import './report.css'
import 'react-datepicker/dist/react-datepicker.css'
import Select, {Theme} from 'react-select'
import {useDownloadExcel} from 'react-export-table-to-excel'
import {useAuth} from '../../../../../auth'
import {getCompanyById} from '../../../../companies/company-list/core/_requests'
import {useThemeMode} from '../../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {getCliniCategoryByplan} from '../../../../client-report/clint-report-list/_requests'
const ClientReportContent = () => {
  const {mode} = useThemeMode()
  const tableRef = useRef(null)
  const [com, setCom] = useState<any>([])
  const [OCcom, setOCcom] = useState('')
  const [OCDate, setOCDate] = useState('')
  const [OCplan, setOCplan] = useState('')
  const [plan, setPlan] = useState<any>([])
  const [report, setReport] = useState<any>([])
  const [notreport, setNotReport] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const {currentUser} = useAuth()
  const [btnExcelDisable, setbtnExcelDisable] = useState(true)
  const [btnClearDisable, setbtnClearDisable] = useState(true)
  var clientID = currentUser?.primaryID
  const [dateRange, setDateRange] = useState([null, null])
  const [planid, setplanid] = useState(0)
  const [startDate, endDate] = dateRange
  const [ClinicCategory, setClinicCategory] = useState<any>([])
  const [clinicselectedOption, setclinicselectedOption] = useState(null)
  const [clinicid, setclinicid] = useState(0)
  useEffect(() => {
    getCompanyById(clientID).then((rest: any) => {
      //
      setOCcom(rest.Data?.name)
    })
  }, [])

  useEffect(() => {
    getCompany().then((res: any) => {
      setCom(res.data.Data)
    })
  }, [])
  useEffect(() => {
    getPlan(clientID).then((res: any) => {
      const mappedOptions = res.data.Data.map((plan: {id: any; name: any}) => ({
        value: plan.id,
        label: plan.name,
      }))
      const allOption = {value: 0, label: 'All'}
      const optionsWithAll = [allOption, ...mappedOptions]
      setPlan(optionsWithAll)
    })
  }, [])

  const companyReport = () => {
    setLoading(true)
    var PID = planid

    var Daterange = (document.getElementById('Daterange') as HTMLInputElement).value

    setOCDate(Daterange)

    getReportforCompany(PID, clientID, clinicid, Daterange).then((resRep) => {
      setReport(resRep.data.Data)
      setLoading(false)

      getReportforCompanyNotIn(PID, clientID).then((resRepNot) => {
        //
        setNotReport(resRepNot.data.Data)
        if (resRep.data?.Data.length > 0 || resRepNot.data.Data.length > 0) {
          setbtnExcelDisable(false)
          setbtnClearDisable(false)
        } else {
          setbtnExcelDisable(true)
          setbtnClearDisable(true)
        }
      })
    })
  }
  const clearReport = () => {
    setclinicselectedOption(null)
    setReport([])
    setNotReport([])
    setCom([])
    getPlan(clientID).then((res: any) => {
      const mappedOptions = res.data.Data.map((plan: {id: any; name: any}) => ({
        value: plan.id,
        label: plan.name,
      }))
      const allOption = {value: 0, label: 'All'}
      const optionsWithAll = [allOption, ...mappedOptions]
      setPlan(optionsWithAll)
    })
    setDateRange([null, null])
    setOCDate('')
    setOCplan('')
    setplanselectedOption(null)
    setbtnExcelDisable(true)
    setbtnClearDisable(true)
  }
  //

  var DatefileName = new Date()
  var fileName =
    DatefileName.getFullYear() + '-' + (DatefileName.getMonth() + 1) + '-' + DatefileName.getDate()

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Client_Report_${fileName}`,
    sheet: 'Users',
  })
  const [planselectedOption, setplanselectedOption] = useState(null)
  const planhandleSelectChange = (planselectedOption: any) => {
    setplanselectedOption(planselectedOption)
    var text = planselectedOption?.label
    setOCplan(text)
    setplanid(planselectedOption?.value)
    setclinicselectedOption(null)
    getCliniCategoryByplan(planselectedOption?.value).then((res: any) => {
      const mappedOptions = res.data.Data.map((com: {id: any; name: any}) => ({
        value: com.id,
        label: com.name,
      }))
      const allOption = {value: 0, label: 'Select'}
      const optionsWithAll = [allOption, ...mappedOptions]
      setClinicCategory(optionsWithAll)

      //setTreatment(res.data.Data);
    })
  }

  //dropdown
  if (mode === 'dark') {
    var customStyles = {
      control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        borderColor: '#393944',

        boxShadow: state.isFocused ? '0 0 0 1px #009ef7' : 'none',
        '&:hover': {
          color: 'red',
        },
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#009ef7' : 'var(--kt-app-bg-color)',
        color: state.isFocused ? 'white' : 'var(--kt-input-solid-color)',
      }),
      menu: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        marginTop: 5,
        borderRadius: 5,
      }),
      placeholder: (provided: any, state: any) => ({
        ...provided,
        color: 'var(--kt-input-solid-color)',
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? 'var(--kt-input-solid-color)' : 'var(--kt-input-solid-color)',
      }),
      indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-input-solid-color)',
      }),
    }
  } else {
    var customStyles = {
      control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        borderColor: '#c5c5c5',

        boxShadow: state.isFocused ? '0 0 0 1px #009ef7' : 'none',
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#009ef7' : 'var(--kt-app-bg-color)',
        color: state.isFocused ? 'white' : 'var(--kt-input-solid-color)',
      }),
      menu: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        marginTop: 5,
        borderRadius: 5,
      }),
      placeholder: (provided: any, state: any) => ({
        ...provided,
        color: 'var(--kt-input-solid-color)',
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? 'var(--kt-input-solid-color)' : 'var(--kt-input-solid-color)',
      }),
      indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: '#c5c5c5',
      }),
    }
  }
  const handleReset = () => {
    setplanselectedOption(null) // or set it to the initial value you want to reset to
  }
  const clinichandleSelectChange = (clinicselectedOption: any) => {
    setclinicselectedOption(clinicselectedOption)

    var text = clinicselectedOption?.label
    //setSelectedClinicName(text)
    setclinicid(clinicselectedOption?.value)
  }

  return (
    <>
      <div className='card '>
        {/* begin::Header */}
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'> Report</h3>
          </div>
          <div className='col-lg-2 pt-5'>
            <button
              className='btn btn-danger col-lg-12'
              disabled={btnExcelDisable}
              onClick={onDownload}
            >
              {' '}
              Export{' '}
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='row mb-10'>
            <div className='col-lg-3'>
              <label className=' col-form-label  fw-bold fs-6'>Filter By Plan</label>
              {/* <select   className='form-select form-select-solid form-select-lg fw-bold' id="PlanID" onChange={planChange}>
                  <option value='0'>All Plan </option>
                  {plan?.map((item: any) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        )
                      })}
                  
                </select> */}
              <Select
                className='my-react-select-container'
                classNamePrefix='my-react-select'
                value={planselectedOption}
                onChange={planhandleSelectChange}
                options={plan}
                isSearchable
                // onInputChange={handleInputChange}
                styles={customStyles}
              />
            </div>
            <div className='col-lg-3'>
              <label className=' col-form-label  fw-bold fs-6'>Filter By Category</label>
              {/* <select   className='form-select form-select-solid form-select-lg fw-bold' value={planid} id="PlanID" onChange={planChange}>
                  <option value='0'>All Plan </option>
                  {plan?.map((item: any) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        )
                      })}
                  
                </select> */}
              <Select
                className='my-react-select-container'
                classNamePrefix='my-react-select'
                value={clinicselectedOption}
                onChange={clinichandleSelectChange}
                options={ClinicCategory}
                isSearchable
                // onInputChange={handleInputChange}
                styles={customStyles}
              />
            </div>
            <div className='col-lg-3'>
              <label className=' col-form-label required fw-bold fs-6'>Filter By Date Range</label>
              <DatePicker
                className='form-control form-control-lg form-control-solid'
                id='Daterange'
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update: any) => {
                  setDateRange(update)
                }}
                isClearable={true}
                dateFormat='dd/MM/yyyy'
              />
            </div>

            <div className='col-lg-2 pt-12'>
              <button onClick={companyReport} className='btn btn-primary col-lg-12'>
                Search
              </button>
            </div>
            <div className='col-lg-2 pt-12'>
              <button
                onClick={clearReport}
                disabled={btnClearDisable}
                className='btn btn-info col-lg-12'
              >
                Clear
              </button>
            </div>
          </div>

          <div className='table-responsive'>
            {/* begin::Table */}
            <table
              className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
              ref={tableRef}
            >
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted' style={{borderBottom: 'none'}}>
                  <th className='min-w-140px' colSpan={5}>
                    {' '}
                    PLAN :{OCplan}
                  </th>
                  <th className='min-w-120px' colSpan={4}>
                    {' '}
                    DATE : {OCDate}
                  </th>
                </tr>
                <tr>
                  <td colSpan={14}></td>{' '}
                </tr>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-50px'> NO</th>
                  <th className='min-w-140px'> EMPLOYEE NAME</th>
                  <th className='min-w-140px'> FAMILY</th>
                  <th className='min-w-140px'> R</th>
                  <th className='min-w-120px'> NRIC</th>
                  <th className='min-w-100px'> WBS CODE </th>
                  <th className='min-w-100px'> STATUS </th>
                  <th className='min-w-100px'> PLAN </th>
                  <th className='min-w-100px'> CATEGORY </th>
                  <th className='min-w-140px'> VALID TILL</th>
                  <th className='min-w-120px'> LIMIT</th>
                  <th className='min-w-120px'> DATE </th>
                  <th className='min-w-100px'> CLINIC </th>
                  <th className='min-w-100px'> DIAGNOSIS</th>
                  <th className='min-w-100px'> PRESCRIPTION</th>
                  {/* <th className='min-w-100px'> CHARGE ( RM )</th>
                <th className='min-w-100px'> BALANCE</th> */}
                  <th className='min-w-100px'> MC</th>
                </tr>
              </thead>
              {loading && (
                <tbody>
                  <tr>
                    <td colSpan={14} style={{textAlign: 'center'}}>
                      {' '}
                      Please wait...{' '}
                    </td>
                  </tr>
                </tbody>
              )}
              {!loading && (
                <tbody>
                  {report?.map((item: any, i: any) => {
                    return (
                      <tr key={i}>
                        <td> {i + 1}</td>
                        <td>
                          {' '}
                          {item?.employeeName}
                          {/* {item?.employeeId} */}
                        </td>
                        <td>
                          {item.familymemberId == '0' ? '-' : item.familymember?.patientName}{' '}
                        </td>
                        <td>
                          {item.familymemberId == '0' ? 'SELF' : item.familymember?.relationship}{' '}
                        </td>
                        <td> {item?.nricPassport} </td>
                        <td> {item?.wbsCode} </td>
                        <td> {item?.suspenseStatus === 2 ? 'Suspended' : 'Active'} </td>
                        <td> {item?.plan?.planName} </td>
                        <td> {item?.cliniccategory?.name} </td>
                        <td>
                          {new Date(item.validityTo).toLocaleDateString('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                          })}
                        </td>
                        <td>
                          {' '}
                          {item.OrgplanUnlimited === '0' || item.OrgplanUnlimited === ''
                            ? item?.OrgplanLimit
                            : 'Unlimited'}{' '}
                        </td>
                        <td>
                          {' '}
                          {new Date(item.visitDate).toLocaleDateString('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                          })}
                        </td>
                        <td> {item.clinic?.clinicName} </td>

                        <td>
                          {item?.diagDetails?.map((items: any, i: any) => {
                            if (item?.diagDetails.length == i + 1) {
                              var slash = ''
                            } else {
                              var slash = '/ '
                            }
                            return (
                              <>
                                <a key={i}> {items?.medicalcondition?.name} </a>
                                <span>{slash}</span>
                              </>
                            )
                          })}
                        </td>
                        <td>
                          {item?.Treatement?.map((items: any, i: any) => {
                            if (item?.diagDetails.length == i + 1) {
                              var slash = ''
                            } else {
                              var slash = '/ '
                            }
                            return (
                              <>
                                <a key={i}> {items?.description} </a>
                                <span>{slash}</span>
                              </>
                            )
                          })}
                        </td>
                        {/* <td>  {item.amount}  </td>
                <td>   {item.plan?.planLimit==="0" ? "Unlimited" : item.plan?.planLimit}  </td> */}
                        <td> {item.totalMcDays} </td>
                      </tr>
                    )
                  })}
                  {notreport?.map((item: any, i: any) => {
                    // return(
                    //   <tr key={i}>
                    //   <td> {report.length+i+1}</td>
                    //   <td> {item.name}-({item.employeeId})       </td>
                    //   <td>       </td>
                    //   <td>       </td>
                    //   <td>  {item.nricPassport}</td>
                    //   <td>  {item.wbsCode}</td>
                    //   <td> {item.plan?.planName} </td>
                    //   <td>  { new Date(item.validityTo).toLocaleDateString("en-GB", { day: "numeric",  month: "short",year: "numeric" }) } </td>
                    //   <td>  {item.currentBalance} </td>
                    //   <td>  </td>
                    //   <td>  </td>
                    //   <td>  </td>
                    //  <td>  </td>
                    //   {/* <td>  </td>
                    //   <td>   {item.currentBalance}</td> */}
                    //   <td> </td>
                    // </tr>
                    //    )
                  })}
                  {report?.length > 0 || notreport?.length > 0 ? (
                    <tr></tr>
                  ) : (
                    <tr>
                      <td colSpan={16} style={{textAlign: 'center'}}>
                        {' '}
                        No Data{' '}
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {ClientReportContent}
