import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'

import { getToken } from '../../../../auth' 

const API_URL = process.env.REACT_APP_API_URLS
const CLAIM_URL = `${API_URL}/claimform`
const REPORT_URL = `${API_URL}/report`
var AuthVerify = getToken.call(this)



const getCompany = async () => {
  const res = await axios.get(`${REPORT_URL}/getCompanydropdown`,  getToken())
  return res;
} 
const getClaimListToAddCharges = async (id:any,year:any,month:any) => {
  const res = await axios.get(`${CLAIM_URL}/getClaimList/${id}/${year}/${month}`, getToken())
  return res;
}
const AddCharges = async (data:any) => {
  const res = await axios.post(`${CLAIM_URL}/mockup/add/OtherCharges`,{data}, getToken())
  return res;
}
export {
  getCompany,getClaimListToAddCharges,AddCharges
}
