import {Route, Routes, Outlet, Navigate, Link} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {AssignSubSobWrapper} from './assign-subsob-list/AssignSubSobWrapper'
 
 
import {KTCard} from '../../../../_metronic/helpers'
import { AlertContext } from '../../../../_metronic/layout/components/alert-context'
import { useContext, useEffect } from 'react'
const csupportBreadcrumbs: Array<PageLink> = [
  {
    title: 'Assign Sub SOB',
    path: '/assign-plan',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
] 
const Notfound = () => {
  const { hideAlert } = useContext(AlertContext);
  useEffect(() => {
    hideAlert(false);
  }, []);
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              
              <PageTitle  breadcrumbs={csupportBreadcrumbs} >Assign Sub SOB </PageTitle>
              <AssignSubSobWrapper />
            </>
          }
        />
        
        <Route index element={<Navigate to='list' />} />
      </Route>
    </Routes>
  )
}

export default Notfound
