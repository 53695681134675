/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { StatisticsWidget1 } from '../../../_metronic/partials/widgets'
import { Table } from './Table'
import { Widget } from './Widget'
import { clinicportalvisitor } from '../core/_request'
import React , { useEffect, useState }from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import { useAuth } from '../../modules/auth'
import { getClinicById } from '../../modules/apps/clinics/clinic-list/core/_requests'
import { StatisticsWidget3 } from './StatisticsWidget3'


const ClinicportalWrapper: FC = () => {
  //Current Date
  const current = new Date();
  const dateCurrent = new Date().toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"});
  ////Current Date
  const intl = useIntl()
  //Data Set
  const { currentUser}=useAuth()
  const [data, setData] = useState<any>();
  const [count, setCount] = useState<any>([]);
  const [com, setCom] = useState<any>([]);
   const updateData = (fieldsToUpdate: Partial<any>): void => {
  const updatedData = Object.assign(data, fieldsToUpdate)
  setData(updatedData)
  }
  var clinicsID=currentUser?.primaryID;
   
  
  useEffect(() => {
    clinicportalvisitor(clinicsID).then((res: any) => {      
    //  debugger
      setCount(res.data.data)   
    });
  }, [])
  useEffect(() => {
    getClinicById(clinicsID).then((res: any) => {
    setCom(res.Data)
    });
  }, [])
   
  return (
    <>
<div className='card-header  row  '> 
      <div className='border-0 col-xl-9 mb-5 '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Dashboard</span>
        </h3> <span className='text-muted mt-1 fw-semibold fs-7'>Dashboard</span>
        <span className='text-muted mt-1 fw-semibold fs-7'> - </span>
        <span className='text-muted mt-1 fw-semibold fs-7'>Dashboard</span>
      
      </div>
    
      </div>
      
      <>
    {/* begin::Row */}
    
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-6'>
        <StatisticsWidget3
          className='card-xl-stretch mb-xl-8'
          color='success'
          title='Total Visits'
          description='Total number of visits made to the clinic'
          change={count?.visitorsCount}
        />
      </div>
      
      
      {/* <div className='col-xl-4'>
        <StatisticsWidget3
          className='card-xl-stretch mb-5 mb-xl-8'
          color='warning'
          title='Total Claim Forms'
          description='Total number of claim form recieved'
          change={claimcount?.totaClaimCountPortal}
        />
      </div> */}

    
      <div className='col-xl-6'>
        <StatisticsWidget3
          className='card-xl-stretch mb-5 mb-xl-8'
          color='primary'
          title='Total Payment Received'
          description='Estimatated total of payments received'
          change={count?.paymentCount}
        />
      </div>
    </div>
    {/* end::Row */}
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-4'>
        <StatisticsWidget1
          className='card-xl-stretch mb-xl-8'
          image='abstract-4.svg'
          title={"Welcome to "+com?.name}
          time={"Date: "+dateCurrent}
          description="Manage and configure your clinic's <br/> setting here"
        />
      </div>
      <div className='col-xl-8'>

        <Widget className="card-xl-stretch mb-xl-8" />

      </div>

    </div>
    {/* end::Row */}
    <div className='col-xl-12'>
      <Table
        className='card-xl-stretch mb-5 mb-xl-8'

      />
    </div>

  </>
    </>
  )
}

export { ClinicportalWrapper }
