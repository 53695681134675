// @ts-nocheck
import {Column} from 'react-table'
import {ClaimInfoCell, ClinicPaymentDate, Claimapprovalstatus} from './ClaimformInfoCell'
import {ClaimformActionsCell} from './ClaimformActionsCell'

import {ClaimformCustomHeader} from './ClaimformCustomHeader'

import {Claim} from '../../core/_models'

const claimformColumns: ReadonlyArray<Column<Claim>> = [
  {
    Header: (props) => (
      <ClaimformCustomHeader tableProps={props} title='#' className='min-w-50px' />
    ),
    accessor: 'serialNo',
  },

  {
    Header: (props) => (
      <ClaimformCustomHeader tableProps={props} title='Patient' className='min-w-125px' />
    ),
    accessor: 'familymember.patientName',
    Cell: ({...props}) => <ClaimInfoCell claim={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ClaimformCustomHeader tableProps={props} title='NRIC/Passport' className='min-w-125px' />
    ),
    accessor: 'nricPassport',
  },
  {
    Header: (props) => (
      <ClaimformCustomHeader tableProps={props} title='WBS Code' className='min-w-125px' />
    ),
    accessor: 'wbsCode',
  },
  {
    Header: (props) => (
      <ClaimformCustomHeader tableProps={props} title='Clinic' className='min-w-125px' />
    ),
    accessor: 'clinicName',
  },
  {
    Header: (props) => (
      <ClaimformCustomHeader tableProps={props} title='Company' className='min-w-125px' />
    ),
    accessor: 'CompanyName',
  },
  {
    Header: (props) => (
      <ClaimformCustomHeader tableProps={props} title='Reference No' className='min-w-125px' />
    ),
    accessor: 'uniqueId',
  },

  {
    Header: (props) => (
      <ClaimformCustomHeader tableProps={props} title='Visit Date' className='min-w-125px' />
    ),
    accessor: 'visitDate',
    Cell: ({...props}) => <ClinicPaymentDate claim={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ClaimformCustomHeader tableProps={props} title='Visit Reason' className='min-w-125px' />
    ),
    accessor: 'visitReason',
  },

  {
    Header: (props) => (
      <ClaimformCustomHeader tableProps={props} title='Amount ( RM )' className='min-w-125px' />
    ),
    accessor: 'amount',
  },
  {
    Header: (props) => (
      <ClaimformCustomHeader tableProps={props} title='Approval Status' className='min-w-125px' />
    ),
    accessor: 'approvalStatus',
    Cell: ({...props}) => <Claimapprovalstatus claim={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ClaimformCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <ClaimformActionsCell
        id={props.data[props.row.index].id}
        claimform={props.data[props.row.index]}
      />
    ),
  },
]

export {claimformColumns}
