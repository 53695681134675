/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAuth } from '../../../auth';
import { getCRUD } from '../../companies/company-list/core/_requests';
import { viewEmployee } from '../client-assign-employee-list/core/_requests'

export function ViewEmployee() {
  const { id } = useParams();

  const [com, setCom] = useState<any>([]);
  const [cli, setCli] = useState<any>([]);
  useEffect(() => {
    viewEmployee(id).then((res: any) => {
      setCom(res.Data)
    });
  }, [])
  const {currentUser}=useAuth();
  const [roleaccess, setroleaccess] = useState<any>([]);
  useEffect(() => {
    getCRUD(currentUser?.role,47).then((res: any) => {
      var crudres=res.data?.Data[0];
      setroleaccess(crudres);

    })
  }, [])
    var editEmployee = "/employees/edit/" + id;

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Employee Details</h3>
          </div>
         {roleaccess?.edit===1 ? (
            <Link to={editEmployee} className='btn btn-primary align-self-center'>
            Edit Employee
          </Link>
         ) : (" ")}
        
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Employee Name</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>  {com.name} </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Email</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{com.email}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Role
            </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{com.role?.roleName}</span>
            </div>
          </div>
          
          
        </div>
      </div>
    </>
  )
}
