import {Route, Routes, Outlet, Navigate,Link} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {ClientSupportListWrapper} from './employee-support-list/ClinicList'
import { ViewClientSupport } from './employee-support-form/ViewClientSupport'
import { useNavigate } from 'react-router-dom'; 
import { AlertContext } from '../../../../_metronic/layout/components/alert-context';
import { useContext, useEffect } from 'react';
import { AlertBox } from '../../../../_metronic/layout/components/alert-message';
const clinicBreadcrumbs: Array<PageLink> = [
  {
    title: 'Insuranceholder Support',
    path: '/employeeSupport',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AdminEmployeeSupportPage = () => {
  const {show,message,type}=useContext(AlertContext)
  const navigate = useNavigate();
  function goBack() {
     
    navigate(-1);
  }
  const { hideAlert } = useContext(AlertContext);
  useEffect(() => {
    hideAlert(false);
  }, []);
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={clinicBreadcrumbs}>Insuranceholder Support</PageTitle>
              <ClientSupportListWrapper />
            </>
          }
        />
       
        <Route
          path='/view/:id'
          element={
            <>
              <PageTitle breadcrumbs={clinicBreadcrumbs}>View Support</PageTitle>
              <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button>
           {show && <AlertBox type={type == "1" ? 'success' : 'error'} message={message} />}
              <ViewClientSupport />
            </>
          }
        />
          
        <Route index element={<Navigate to='list' />} />
        
      </Route>
    </Routes>
  )
}

export default AdminEmployeeSupportPage
