/* eslint-disable jsx-a11y/anchor-is-valid */
import { useIntl } from 'react-intl'
 

import React , { FC,useEffect, useState }from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../../modules/auth'
import { StatisticsWidget3 } from './StatisticsWidget3'
import { widgetCount } from '../../../role-access-setting/role-setting-list/core/_requests'
import { EmployeeListWrapper } from '../../../clinic-assign-employee/clinic-assign-employee-list/EmployeeList'
 
const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const {currentUser}=useAuth();
  //Data Set
  const [data, setData] = useState<any>();
  const [count, setCount] = useState<any>([]);
   const updateData = (fieldsToUpdate: Partial<any>): void => {
  const updatedData = Object.assign(data, fieldsToUpdate)
  setData(updatedData)
  }
   
 //
  useEffect(() => {
    widgetCount(3,currentUser?.primaryID,3,currentUser?.primaryID).then((res: any) => {      
    setCount(res.data.Data)   
    });
  }, [])
  
return (
     <>
    <div className='card-header  row  '> 
      <div className='border-0 col-xl-7 mb-5 '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Roles & Permissions Overview</span>
        </h3> <span className='text-muted mt-1 fw-semibold fs-7'>Dashboard</span>
        <span className='text-muted mt-1 fw-semibold fs-7'> - </span>
        <span className='text-muted mt-1 fw-semibold fs-7'>Roles & Permissions Overview</span>
      
      </div>
      <div className="row mb-5  col-xl-5 col-12 col-sm-12 col-md-12 px-0">
     
      <div
      className='px-0 col-xl-4 col-4 col-sm-4 col-md-4 card-toolbar'
      data-bs-toggle='tooltip'
      data-bs-placement='top'
      data-bs-trigger='hover'
      title='Click to view all employee'
    ><Link  style={{float: "right"}}
    to='/clinic-roles/list'
    className='col-lg-11 btn btn-sm btn-primary'
    // data-bs-toggle='modal'
    // data-bs-target='#kt_modal_invite_friends'
     
  >
   
   Add Role
    </Link>
    </div>
      
     
      <div
          className='px-0 col-xl-4  col-4 col-sm-4 col-md-4 card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to view all employee'
        ><Link   style={{float: 'left',marginLeft: '10px'}}
        to='/assign-clinic-employee/add'
        className='col-lg-11 btn btn-sm btn-primary'
        // data-bs-toggle='modal'
        // data-bs-target='#kt_modal_invite_friends'
      >
       
        Add User
        </Link>
        </div>
        <div
          className='px-0 col-xl-4  col-4 col-sm-4 col-md-4 card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to view all employee'
        ><Link   style={{display: 'block',marginLeft: '10px'}}
        to='/clinic-role-permission/list'
        className='btn btn-sm btn-primary'
        // data-bs-toggle='modal'
        // data-bs-target='#kt_modal_invite_friends'
      >
       
        Manage Permissions
        </Link>
        </div>
        
      </div>
      </div>
      <>
  
    
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-6'>
        <StatisticsWidget3
          className='card-xl-stretch mb-xl-8'
          color='danger'
          title='Total Users'
          description='Total number of users assigned'
          change={count?.usersCount}
        />
      </div>
      <div className='col-xl-6'>
        <StatisticsWidget3
          className='card-xl-stretch mb-xl-8'
          color='success'
          title='Total Roles'
          description='Total number of roles created'
          change={count?.rolesCount}
        />
      </div>
    </div>
      
  <EmployeeListWrapper/>
   
  </>
  </>
  )
}





export { DashboardWrapper }
