import axios, {AxiosResponse} from 'axios'
 
import {getToken} from '../../../../../auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS
const BANNER_URL = `${API_URL}`
 

var AuthVerify=getToken.call(this);

const getBanner = () => {
  return axios
    .get(`${BANNER_URL}/app/listBanner`,getToken())
    .then(d => d.data)
}
const addBanner = (data:FormData) => {
  return axios
    .post(`${BANNER_URL}/app/addBanner`,data,getToken())
    .then(d => d.data)
}
const updateBanner = (data:FormData) => {
  return axios
    .put(`${BANNER_URL}/app/updateBanner`,data,getToken())
    .then(d => d.data)
}
const deleteBanner = (id:any) => {
  return axios
    .get(`${BANNER_URL}/app/deleteBanner/${id}`,getToken())
    .then(d => d.data)
}
const viewBanner = (id:any) => {
  return axios
    .get(`${BANNER_URL}/app/viewBanner/${id}`,getToken())
    .then(d => d.data)
}

export {getBanner,addBanner,viewBanner,updateBanner,deleteBanner}
