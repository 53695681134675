import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Claim, Fetchclaim, ClaimQueryResponse} from './_models'
import {getToken} from '../../../../auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS
const CLAIM_URL = `${API_URL}/report/company/nonpanelReport` 
 
var AuthVerify = getToken.call(this)

 
 
const getClaimform = (query: string,id:any): Promise<ClaimQueryResponse> => {
  return axios
    .post(`${CLAIM_URL}/query/?${query}`,{id}, getToken())
    .then((d: AxiosResponse<ClaimQueryResponse>) => {
     
      return d.data
    })
} 
const FetchNonclaimholders = (id: any) => {
  return axios
    .get(`${API_URL}/insuranceHolder/${id}`, getToken())
    .then((d) => {
     //
      return d.data
    })
} 
const FetchNonclaimholdersByid = (id: any) => {
  return axios
    .get(`${API_URL}/nonPanelClaimform/${id}`, getToken())
    .then((d) => {
     //
      return d.data
    })
} 
const approvenonpanelclaim = (id: any,insuranceHolderId:any,amount:any) => {
  return axios
    .post(`${API_URL}/nonPanelClaimform/approve/${id}`,{insuranceHolderId,amount}, getToken())
    .then((d) => {
     //
      return d.data
    })
} 
const getNonPanelReport = (date: any) => {
  return axios
    .post(`${API_URL}/report/NonpanelclaimformsReport`,{date}, getToken())
    .then((d) => {
     //
      return d.data
    })
} 
const deleteClaimFormById = (id: any,type:any) => {
  return axios.get(`${API_URL}/nonPanelClaimform/deleteClaimForm/${id}/${type}`, getToken()).then((response) => {
   // 
    return response.data
  })
}
export {
  getClaimform,FetchNonclaimholders,FetchNonclaimholdersByid,deleteClaimFormById,approvenonpanelclaim,getNonPanelReport
  
}
