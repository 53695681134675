import React, { useContext, useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { addBanner } from '../banner-list/core/_requests'
import {Banner, initialValues } from '../banner-list/core/_models'
import {  Link, useNavigate } from 'react-router-dom';
 
import { AlertContext } from '../../../../../../_metronic/layout/components/alert-context'
interface Event<T = EventTarget> {
  target: T
  // ...
}


const clinicDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  title: Yup.string().required('Title is required'),
 // bannerimage: Yup.().required('Image  is required'),
  description: Yup.string().required('Description  is required'),
  

})

const ClinicDetails: React.FC = () => {
  const{showAlert}=useContext(AlertContext);
  const navigate = useNavigate();
  const [data, setData] = useState<Banner>(initialValues)
  const [formImage, setFormImage] = useState<File | null>(null)
  const [btnDisable, setbtnDisable] = useState(true)
  const [loading, setLoading] = useState(false)
  const formik = useFormik<Banner>({
    initialValues,
    validationSchema: clinicDetailsSchema,
    onSubmit: (values,actions) => {
      actions.resetForm({
      values: initialValues})
      setLoading(true)
      setTimeout(() => {
       
       const updatedData = Object.assign(data, values)
       
       const formData = new FormData()
       formData.append('name', updatedData?.name)
       formData.append('title', updatedData?.title)
       formData.append('description', updatedData?.description)
       formData.append('createdBy', '1')
       
    
       if (formImage) formData.append('bannerimage', formImage)
       addBanner(formData).then((res) => {
        showAlert(res?.Message,res?.Code);
        navigate('/mobile-settings/list', {replace: true},)
        }) 
      
        setLoading(false)
      }, 1000)
    },
  })
 // 
 const imageHandler = (e: Event<HTMLInputElement>) => {
  formik.handleChange(e);
 const reader = new FileReader()
 reader.onload = () => {
  // 
   if (reader.readyState === 2) {
    
    
    setData({
     ...data,
     bannerimage: reader.result,
   })
  
}
 }


 const files = e.target.files

 if (files) {
   setFormImage(files[0])
   reader.readAsDataURL(files[0])
 }
 if (data?.bannerimage !== null && data?.name!==null && data?.title!==null&& data?.description!==null) {
  //
  setbtnDisable(false);
}
}

  return (
    
    <form onSubmit={formik.handleSubmit} noValidate className='form'>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>New Banner</h3>
          </div>
         
        </div>
       
        <div id='kt_account_profile_details'>


          <div className='card-body border-top p-9'>

          <div className='row'>
            {/** name */}
            <div className='mb-6 col-lg-6'>
              <label className='col-lg-12 col-form-label required fw-bold fs-6'>Banner Name</label>
              <div className='col-lg-12'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter banner name'
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>
              
            {/** title */}
            <div className='mb-6 col-lg-6'>
              <label className='col-lg-12 col-form-label required fw-bold fs-6'>Banner Title</label>
              <div className='col-lg-12'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter banner name'
                  {...formik.getFieldProps('title')}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.title}</div>
                  </div>
                )}
              </div>
            </div>
            </div>
            <div className='row'>
            {/** image */}
            <div className='col-lg-6 mb-6'>
              <label className='col-lg-12 col-form-label required fw-bold fs-6'>Banner Image</label>
              <div className='col-lg-12'>
                <input
                  type='file' 
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter banner name' 
                  {...formik.getFieldProps('bannerimage')} onChange={imageHandler}
                />
                {formik.touched.bannerimage && formik.errors.bannerimage && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>Image is Required</div>
                  </div>
                )}
              </div>
            </div>
             {/** description */}
             <div className='col-lg-6 mb-6'>
              <label className='col-lg-12 col-form-label required fw-bold fs-6'>Banner Description</label>
              <div className='col-lg-12'>
                <textarea
                   
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter banner description'
                  {...formik.getFieldProps('description')}
                />
                {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.description}</div>
                  </div>
                )}
              </div>
            </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <button type='submit' className='btn btn-primary' disabled={btnDisable}>
                    {!loading && 'Save Changes'}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
          </div>
        </div>
      </div>
      

    </form>
  )
}

export default ClinicDetails
