import React, { FC, useContext, useState } from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
 
 
import {useNavigate, useParams} from 'react-router-dom'
import { AlertContext } from '../../../../../../_metronic/layout/components/alert-context'
import { Contact, initialValues } from '../plan-list/core/_models'
import { updateBackendSettings } from '../plan-list/core/_requests'
 


 
const contactSchema = Yup.object().shape({
  adminEmergencyContact: Yup.string().required('Admin Emergency Contact is required'),
  ambulanceContact: Yup.string().required('Ambulance Contact is required'),
  //editbannerimage: Yup().required('Image  is required'),
  fireContact: Yup.string().required('Fire Contact  is required'),
  about: Yup.string().required('About  is required'),
})
type Props = {
  initialValues: Contact
}
export const EditContact: FC<Props> = ({initialValues}) => {

  const{showAlert}=useContext(AlertContext);
  const navigate = useNavigate()
  
  const [data, setData] = useState<Contact>(initialValues)
   {/* Add Row and column Multiple*/}
  const { id }=useParams();
  const [btnDisable, setbtnDisable] = useState(true)
 

  const [loading, setLoading] = useState(false)
  const formik = useFormik<Contact>({
    initialValues,
    validationSchema: contactSchema,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        
        const updatedData = Object.assign(data, values)
        
        updateBackendSettings(updatedData).then((res) => {
          showAlert(res?.Message,res?.Code);
         navigate('/mobile-settings/list', {replace: true},)
          }) 
      }, 1000)
    }, 
  })
  
  return (
    <> <form onSubmit={formik.handleSubmit} noValidate className='form'>
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Contact Details</h3>
        </div>
       
      </div>
     
      <div id='kt_account_profile_details'>


        <div className='card-body border-top p-9'>

        <div className='row'>
          {/** name */}
          <div className='mb-6 col-lg-6'>
            <label className='col-lg-12 col-form-label required fw-bold fs-6'>Emergency Contact</label>
            <div className='col-lg-12'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter emergency contact'
                {...formik.getFieldProps('adminEmergencyContact')}
              />
              {formik.touched.adminEmergencyContact && formik.errors.adminEmergencyContact && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.adminEmergencyContact}</div>
                </div>
              )}
            </div>
          </div>
          <div className='mb-6 col-lg-6'>
            <label className='col-lg-12 col-form-label required fw-bold fs-6'>Ambulance Contact</label>
            <div className='col-lg-12'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter ambulance contact'
                {...formik.getFieldProps('ambulanceContact')}
              />
              {formik.touched.ambulanceContact && formik.errors.ambulanceContact && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.ambulanceContact}</div>
                </div>
              )}
            </div>
          </div> 
          {/** title */}
          <div className='mb-6 col-lg-6'>
            <label className='col-lg-12 col-form-label required fw-bold fs-6'>Fire Contact</label>
            <div className='col-lg-12'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter fire contact'
                {...formik.getFieldProps('fireContact')}
              />
              {formik.touched.fireContact && formik.errors.fireContact && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.fireContact}</div>
                </div>
              )}
            </div>
          </div>
          {/** description */}
          <div className='col-lg-6 mb-6'>
            <label className='col-lg-12 col-form-label required fw-bold fs-6'>About Description</label>
            <div className='col-lg-12'>
              <textarea
                 
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter about description'
                {...formik.getFieldProps('about')}
              />
              {formik.touched.about && formik.errors.about && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.about}</div>
                </div>
              )}
            </div>
          </div>
          </div>
           
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {!loading && 'Save Changes'}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
        </div>
      </div>
    </div>
    

  </form>
    </>
  )
                    }

export default EditContact
 

