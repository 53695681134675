/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {recentClinics} from '../core/_request'
import {listRecentClinics, initialValues} from '../core/_models'
import Avatar from 'react-avatar'
import {Link, useNavigate} from 'react-router-dom'
import {getCRUD} from '../../modules/apps/companies/company-list/core/_requests'
import {useAuth} from '../../modules/auth'

type Props = {
  className: string
}

const Table: React.FC<Props> = ({className}) => {
  const navigate = useNavigate()
  const [data, setData] = useState<listRecentClinics>(initialValues)
  const [clinics, setClinics] = useState<any>([])
  const updateData = (fieldsToUpdate: Partial<listRecentClinics>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  useEffect(() => {
    recentClinics().then((res: any) => {
      setClinics(res.data.Data)
     
    })
  }, [])
  const viewClinicDetails = (Id: any) => {
    navigate('/clinics/view/' + Id)
  }
  const {currentUser} = useAuth()
  const [clinicaccess, setclinicaccess] = useState<any>([])

  useEffect(() => {
    getCRUD(currentUser?.role, 39).then((res: any) => {
      var crudres = res.data?.Data[0]
      setclinicaccess(crudres)
    })
  }, [])
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Clinics</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>List of recently created clinics</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to view all data'
        >
          {clinicaccess?.list === 1 ? (
            <Link
              to='/clinics/list'
              className='btn btn-sm btn-primary'
              // data-bs-toggle='modal'
              // data-bs-target='#kt_modal_invite_friends'
            >
              <KTSVG path='media/icons/duotune/files/fil001.svg' className='svg-icon-3' />
              View All
            </Link>
          ) : (
            ''
          )}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Clinic Name</th>
                <th className='min-w-140px'> Clinic Email</th>
                <th className='min-w-120px'> Clinic Phone</th>
                <th className='min-w-120px'> Clinic State</th>
                <th className='min-w-120px'> Clinic Country</th>
                {clinicaccess?.view === 1 ? <th className='min-w-100px text-end'>Actions</th> : ''}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {clinics?.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          <Avatar size='40' round='20px' name={item?.name} maxInitials={2} />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          
                            {item?.name}
                          
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            Clinic
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>{item?.email}</td>
                    <td>{item?.telephoneNumber}</td>
                    <td>
                      {item?.state}
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {item?.pincode}
                      </span>
                    </td>
                    <td>{item?.country}</td>
                    {clinicaccess?.view === 1 ? (
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <a
                            onClick={() => viewClinicDetails(item?.id)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/eye.svg'
                              className='svg-icon-3'
                            />
                          </a>

                          {/* <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a> */}
                        </div>
                      </td>
                    ) : (
                      ''
                    )}
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {Table}
