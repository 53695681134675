import {Route, Routes, Outlet, Navigate,Link} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {ClinicListWrapper} from './clinic-support-list/ClinicList'
import { ViewClinicSupport } from './clinic-support-form/ViewClinicSupport'
import { AlertBox } from '../../../../_metronic/layout/components/alert-message'
import { AlertContext } from '../../../../_metronic/layout/components/alert-context'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
const clinicBreadcrumbs: Array<PageLink> = [
  {
    title: 'Clinics Support',
    path: '/clinic-support',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AdminsupportPage = () => {
  const navigate = useNavigate();
  function goBack() {
     
    navigate(-1);
  }
  const { hideAlert } = useContext(AlertContext);
  useEffect(() => {
    hideAlert(false);
  }, []);
  const {show,message,type}=useContext(AlertContext)
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={clinicBreadcrumbs}>Clinic Support</PageTitle>
               
              <ClinicListWrapper />
            </>
          }
        />
       
        <Route
          path='/view/:id'
          element={
            <>
              <PageTitle breadcrumbs={clinicBreadcrumbs}>View Support</PageTitle>
              <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button> 
           {show && <AlertBox type={type == "1" ? 'success' : 'error'} message={message} />}
              <ViewClinicSupport />
            </>
          }
        />    
        <Route index element={<Navigate to='list' />} /> 
      </Route>
    </Routes>
  )
}

export default AdminsupportPage
