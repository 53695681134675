/* eslint-disable jsx-a11y/anchor-is-valid */
import {useContext, useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'
//import { fetchData, getClaimformById, getfamilymember, getMCDetails, getTreatementDetails } from '../nonpanel-claim-list/core/_requests'
import {Modal, Button} from 'react-bootstrap'
import Avatar from 'react-avatar'
import {
  approvenonpanelclaim,
  deleteClaimFormById,
  FetchNonclaimholders,
  FetchNonclaimholdersByid,
} from '../nonpanel-claim-list/core/_requests'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import axios from 'axios'
import fileDownload from 'js-file-download'
export function ViewClaimform() {
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const {id} = useParams()
  const [showModal, setShow] = useState(false)
  const [showRejectModal, setRejectShow] = useState(false)
  const [claim, setClaim] = useState<any>([])
  const [emp, setEmp] = useState<any>([])
  const handleClose = () => setShow(false)
  const handleRejectClose = () => setRejectShow(false)
  const handleShow = () => setShow(true)
  const handleRejectShow = () => setRejectShow(true)

  useEffect(() => {
    FetchNonclaimholdersByid(id).then((res: any) => {
      //
      setClaim(res.Data)
      var employyeId = res.Data?.insuranceHolderId

      FetchNonclaimholders(employyeId).then((result) => {
        setEmp(result.Data)
      })
    })
  }, [])

  const approveClaim = () => {
    approvenonpanelclaim(claim?.id, claim?.insuranceHolderId, claim?.amount).then((res) => {
      setShow(false)
      var message = ''
      var code = ''
      if (res.Data === 'unlimited') {
        var code = '1'
        var message = 'Claimform approved successfully'
      }
      if (res.Data === 'insufficient') {
        var code = '0'
        var message =
          'The claim form was not approved because the employee has an insufficient balance'
      }
      if (res.Data === 'sufficient') {
        var code = '1'
        var message = 'Claimform approved successfully'
      }
      showAlert(message, code)
      navigate('/nonpanel-claim-form/list', {replace: true})
    })
  }
  const rejectClaim = () => {
    deleteClaimFormById(claim?.id, 3).then((resp) => {
      setShow(false)
      showAlert(resp?.Message, resp?.Code)
      navigate('/nonpanel-claim-form', {replace: true})
    })
  }
  const downloadUrl = claim?.prescription

  const downloadFileAtUrl = (url: any, Inv: any) => {
    const fileName = url.split('/').pop()
    const fileExtension = fileName.split('.').pop()
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        const DownloadedfileName = Inv + '.' + fileExtension
        fileDownload(res.data, DownloadedfileName)
      })
  }
  return (
    <>
      <div className='tab-content' id='myTabContent'>
        <div className='mb-xl-10 tab-pane fade active show' id='kt_tab_overview' role='tabpanel'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Employee Info</h3>
              </div>
              <div className='card-title m-0'>
                {claim?.approvalStatus === 1 ? (
                  <a className='btn btn-success align-self-center ' style={{marginRight: '5px'}}>
                    <KTSVG
                      path='/media/icons/duotune/general/gen048.svg'
                      className='svg-icon-4 me-1'
                    />{' '}
                    Claim Approved{' '}
                  </a>
                ) : (
                  <a
                    className='btn btn-primary align-self-center '
                    onClick={handleShow}
                    style={{marginRight: '5px'}}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen048.svg'
                      className='svg-icon-4 me-1'
                    />{' '}
                    Approve Claim
                  </a>
                )}
                {claim?.approvalStatus === 2 ? (
                  <a className='btn btn-danger align-self-center'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen048.svg'
                      className='svg-icon-4 me-1'
                    />{' '}
                    Claim Rejected
                  </a>
                ) : (
                  <a className='btn btn-danger align-self-center' onClick={handleRejectShow}>
                    <KTSVG
                      path='/media/icons/duotune/general/gen048.svg'
                      className='svg-icon-4 me-1'
                    />{' '}
                    Reject Claim
                  </a>
                )}
              </div>
            </div>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='me-7 mb-4'>
                  {emp?.profileImage ? (
                    <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                      <img
                        src={emp?.profileImage}
                        className='w-100'
                        style={{objectFit: 'contain'}}
                      />
                    </div>
                  ) : (
                    <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                      <Avatar
                        name={emp?.name}
                        maxInitials={2}
                        round
                        color='#607458'
                        fgColor='#1b1b29'
                      ></Avatar>
                    </div>
                  )}
                </div>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <a className='text-gray-800  fs-2 fw-bolder me-1'>
                          {emp.name} - ( {emp.employeeId})
                        </a>
                      </div>
                      <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                        <a href='#' className='d-flex align-items-center text-gray-400  me-5 mb-2'>
                          <KTSVG
                            path='/media/icons/duotune/communication/com006.svg'
                            className='svg-icon-4 me-1'
                          />
                          {emp?.department?.name}
                        </a>
                      </div>
                      {emp.client?.name ? (
                        <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                          <a className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'>
                            {emp.client?.name}
                          </a>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className='d-flex flex-wrap flex-stack'>
                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                      <div className='d-flex flex-wrap'>
                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <KTSVG
                              path='/media/icons/duotune/general/telephone.svg'
                              className='svg-icon-3 svg-icon-success me-2'
                            />
                            <div className='fs-7 fw-bolder'>{emp.mobileNumber}</div>
                          </div>
                        </div>
                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <KTSVG
                              path='/media/icons/duotune/general/envelope-at.svg'
                              className='svg-icon-3 svg-icon-success me-2'
                            />
                            <div className='fs-7 fw-bolder'>{emp.email}</div>
                          </div>
                        </div>
                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <div className='fs-7 fw-bolder'>RM {claim.amount}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='tab-content' id='myTabContent'>
        <div className='mb-xl-10 tab-pane fade active show' id='kt_tab_overview' role='tabpanel'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Reason</h3>
              </div>
            </div>
            <div className='card-body pt-9 pb-0'>
              {claim?.visitDate != null ? (
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>Visit Date</label>
                  <div className='col-lg-8'>
                    <a className='fw-bold fs-6 text-dark  '>
                      {new Date(claim?.visitDate).toLocaleDateString('en-GB', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      })}
                    </a>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Reason</label>
                <div className='col-lg-8'>
                  <a className='fw-bold fs-6 text-dark  '>{claim?.visitReason}</a>
                </div>
              </div>
            </div>
          </div>

          <div className='card mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Prescription</h3>
              </div>
              <div className='card-title m-0'>
                <a
                  className='btn btn-primary'
                  onClick={() => downloadFileAtUrl(downloadUrl, claim?.uniqueId)}
                >
                  Download File
                </a>
              </div>
            </div>

            <div className='card-body pt-9 pb-0'>
              <img src={claim?.prescription} style={{width: '500px', height: '500px'}} />
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Header>
          Are you sure you want to approve this claim for RM {claim?.amount} ?
        </Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Not Now
          </Button>

          <Button variant='primary' onClick={approveClaim}>
            Yes, Approve
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showRejectModal} onHide={handleRejectClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Header>
          Are you sure you want to reject this claim for RM {claim?.amount} ?
        </Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleRejectClose}>
            Not Now
          </Button>

          <Button variant='primary' onClick={rejectClaim}>
            Yes, Reject
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal */}
    </>
  )
}
