import React, { FC, useContext, useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {  getRole, updateEmployee } from '../client-assign-employee-list/core/_requests'
import { AddEmployee,initialValues } from '../client-assign-employee-list/core/_models'
import {useNavigate, useParams} from 'react-router-dom'
import { useAuth } from '../../../auth'
import { AlertContext } from '../../../../../_metronic/layout/components/alert-context'



const clinicDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Employee Name is required'),
  roleId: Yup.string().required('Employee Role is required'),
  email: Yup.string().required('Employee Email  is required'),
})

type Props = {
  initialValues: AddEmployee
}
export const EditBasicDetails: FC<Props> = ({initialValues}) => {
  const{showAlert}=useContext(AlertContext);
  const navigate = useNavigate();
 const {id}= useParams();
  const [role, setRole] = useState<any>([]);
  const [splittedSob, setsplittedSob] = useState<any>([]);
  const [checkedItems, setCheckedItems] = React.useState([]);
  const [loading, setLoading] = useState(false)
  
   //get type
   const {currentUser} = useAuth();
    
     const type=2;
   
 
   //end get type
  const formik = useFormik<AddEmployee>({
    initialValues,
    validationSchema: clinicDetailsSchema,
    onSubmit: (values,actions) => {
      actions.resetForm({
      values: initialValues})
      setLoading(true)
      setTimeout(() => {
      
       
       //values.roleId=splittedSob;
       
        values.type=type;
        updateEmployee(values,id).then(
          (res)=>{
            showAlert(res?.Message,res?.Code);
            navigate('/employees/list', {replace: true})
          }
        )
        setLoading(false)
      }, 1000)
    },
  })
  
  useEffect(() => {
    getRole(currentUser?.primaryID).then((res: any) => {
      setRole(res.data.Data)

    });
  }, [])
  
  return (

    <form onSubmit={formik.handleSubmit} noValidate className='form'>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Update Employee</h3>
          </div>
         
        </div>
       
        <div id='kt_account_profile_details'>


          <div className='card-body border-top p-9'>

            {/** Name */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Employee Name</label>
              <div className='col-lg-8'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter employee name'
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>
            {/** Role */}
            <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Employee Role</label>
            <div className='col-lg-8'>
                  <select
                        className='form-select form-select-solid form-select-lg fw-bold'
                        {...formik.getFieldProps('roleId')}
                      >
                        <option value=''>Select a Role...</option>
                        {role.map((item: any, i: any) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </select>
                  </div>
            </div>
            {/** Email */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>
              <div className='col-lg-8'>
                <input
                  type='email'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter employee email)'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>
            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Username</label>


              <div className='col-lg-8 fv-row'>
              <input
                  type='text' autoComplete='off'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Employee Name'
                  {...formik.getFieldProps('username')}
                />
                {formik.touched.username && formik.errors.username && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.username}</div>
                  </div>
                )}

              </div>
            </div> */}
            {/** Username */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Password</label>
              <div className='col-lg-8'>
              <input
                  type='password' autoComplete='off'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Enter employee password'
                  {...formik.getFieldProps('password')}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.password}</div>
                  </div>
                )}
              </div>
            </div>
            {/*  */}
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-danger' disabled={loading}>
                {!loading && 'Save'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            
          </div>
        </div>
      </div>
      
    </form>
  )
                    }

export default EditBasicDetails
 

