import axios, {AxiosResponse} from 'axios'
import {getToken} from '../../../../../auth'

//
//
const API_URL = process.env.REACT_APP_API_URLS
const C_COMON_URL = `${API_URL}/dashboard`

const vistGraph = async () => {
  const result = await axios.get(`${C_COMON_URL}/visitgraph`, getToken())
  //
  return result
}
const ClaimApprovedGraph = async () => {
  const result = await axios.get(`${C_COMON_URL}/approvedClaimgraph`, getToken())
  //
  return result
}
const datewisecreditcompanyGraph = async (data: any) => {
  const result = await axios.post(`${API_URL}/report/comapany/creditwiseReport`, data, getToken())
  //
  return result
}
const datewisevisitGraph = async (data: any) => {
  const result = await axios.post(`${API_URL}/report/comapany/monthwiseReport`, data, getToken())
  //
  return result
}
export {ClaimApprovedGraph, vistGraph, datewisevisitGraph, datewisecreditcompanyGraph}
