import axios, { AxiosResponse } from 'axios'
import { RecentClinicsQueryResponse, RecentSubsobQueryResponse } from './_models'
import { getToken } from '../../modules/auth/core/AuthHelpers'
import { RecentClientQueryResponse } from './_models'
import { RecentrecentInsuranceHolderQueryResponse } from './_models'
 

var AuthVerify = getToken.call(this);
const API_URL = process.env.REACT_APP_API_URLS
const WIDGET_URL = `${API_URL}/dashboard/widgetCount`
const WIDGET_SOB_URL = `${API_URL}/dashboard/widgetCountSOB`
const TABLE_URL = `${API_URL}/dashboard/recentClinics`
const SUBSOB_URL = `${API_URL}/dashboard/recentSubsob`
const CLIENT_WIDGET_URL = `${API_URL}/dashboard/widgetCountClient`
const CLIENT_TABLE_URL = `${API_URL}/dashboard/recentClient`
const CLIENT_R_ETABLE_URL = `${API_URL}/dashboard/portal`
const IH_TABLE_URL = `${API_URL}/dashboard/recentEmployees`
const CLAIM_URL = `${API_URL}/dashboard/recentClailforms`
const CLINIC_V_URL = `${API_URL}/dashboard/Clientportalvisitors`
const C_E_URL = `${API_URL}/dashboard/employeePortalCount`
const C_COMON_URL = `${API_URL}/dashboard`

const widgetCount = async (): Promise<any> => {
  const result = await axios.get(`${WIDGET_URL}`, getToken());
 
  return result;
}
const recentClinics = async (): Promise<RecentClinicsQueryResponse> => {
  const result = await axios.get(`${TABLE_URL}`, getToken());

  return result;
 
}

const recentSubsob = async (): Promise<RecentSubsobQueryResponse> => {
  const result = await axios.get(`${SUBSOB_URL}`, getToken());

  return result;
}
const widgetCountClient = async (): Promise<any> => {
  //debugger
  const result = await axios.get(`${CLIENT_WIDGET_URL}`, getToken()); 
  
  return result;
}
const widgetCountSOB = async (): Promise<any> => {
  const result = await axios.get(`${WIDGET_SOB_URL}`, getToken());
  
  return result;
}
const recentClient = async (): Promise<RecentClientQueryResponse> => {
  const result = await axios.get(`${CLIENT_TABLE_URL}`, getToken());
  
  return result;
 
}
const clientPortalrecentemployee = async (clientID:any): Promise<RecentClientQueryResponse> => {
  const result = await axios.get(`${CLIENT_R_ETABLE_URL}/${clientID}`, getToken());

  return result;
 
}
const recentInsuranceHolder= async (): Promise<RecentrecentInsuranceHolderQueryResponse> => {
  const result = await axios.get(`${IH_TABLE_URL}`,getToken());
  return result;
 
}
// clinic portal
const recentClaims = async (clinicId:any): Promise<RecentClinicsQueryResponse> => {
  const result = await axios.get(`${CLAIM_URL}/${clinicId}`, getToken());
 
  return result;
 
}
const clinicportalvisitor = async (clinicId:any): Promise<any> => {
  const result = await axios.get(`${CLINIC_V_URL}/${clinicId}`, getToken());
 
  return result;
}
 
const clientportalEmpCount = async (clientID:any): Promise<any> => {
  const result = await axios.get(`${C_E_URL}/${clientID}`, getToken());
  
  return result;
}
const adminrecentClailforms= async (): Promise<any> => {
  const result = await axios.get(`${C_COMON_URL}/adminrecentClailforms`, getToken());
  
  return result;
}
const getsupportwidget= async () => {
  const result = await axios.get(`${API_URL}/support/widget`, getToken());
  
  return result;
}
const getsupportrecentclients= async () => {
  const result = await axios.get(`${API_URL}/support/recentSupportClients`, getToken());
 
  return result;
}
const recentSupportClinic= async () => {
  const result = await axios.get(`${API_URL}/support/recentSupportClinic`, getToken());
  
  return result;
}
const recentSupportEmployeee= async () => {
  const result = await axios.get(`${API_URL}/support/recentSupportEmployeee`, getToken());
 
  return result;
}
const vistGraph= async () => {
  const result = await axios.get(`${C_COMON_URL}/visitgraph`, getToken());
  
  return result;
}
const PaymentSentGraphs= async () => {
  const result = await axios.get(`${C_COMON_URL}/paymentsentgraph`, getToken());
 
  return result;
}
const PaymentReceiveGraphs= async () => {
  const result = await axios.get(`${C_COMON_URL}/paymentreceivegraph`, getToken());
  
  return result;
}
const ClaimApprovedGraph= async () => {
  const result = await axios.get(`${C_COMON_URL}/approvedClaimgraph`, getToken());
  
  return result;
}
export { widgetCount, recentClinics,recentSupportClinic,recentSupportEmployeee,getsupportrecentclients,
  recentSubsob,getsupportwidget,
  widgetCountClient,vistGraph,ClaimApprovedGraph,PaymentReceiveGraphs,PaymentSentGraphs,
  recentClient,clientPortalrecentemployee,clientportalEmpCount,adminrecentClailforms,
  recentInsuranceHolder,widgetCountSOB,recentClaims,clinicportalvisitor}