 
import { useContext } from 'react'
 
import { AlertContext } from '../../../../../../_metronic/layout/components/alert-context'
import { AlertBox } from '../../../../../../_metronic/layout/components/alert-message'
import { ListPage } from './ListPage'
 
const Main = ()=>{
  const { show, message,type } = useContext(AlertContext);
  // 
  return(
  <div className='row'>
    
    <ListPage></ListPage>
   
  </div>
   )
}

const PlanListWrapper = () => (
   <Main/>
)

export {PlanListWrapper}
