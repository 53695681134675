import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../../_metronic/helpers'
import { InsuranceHolder, InsuranceHolderQueryResponse, initialValue } from './_models'
import { getToken } from '../../../../auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS
const IH_URL = `${API_URL}/insuranceHolder`
const GET_IH_LIST = `${API_URL}/insuranceHolder/query`
const SOB_URL = `${API_URL}/plan/getSubSob`
const COUNTRY_URL=`${API_URL}/dashboard/countries`
const EXCEL_URL=`${API_URL}/bulkInsert/upload`
var AuthVerify = getToken.call(this);
const GET_PLAN_LIST = `${API_URL}/plan`

//
const getInsuranceHolder = (query: string): Promise<InsuranceHolderQueryResponse> => {
  //
  return axios
    .get(`${GET_IH_LIST}?${query}`, getToken())
    .then((d: AxiosResponse<InsuranceHolderQueryResponse>) => {
      // 
      return d.data
    })
}
const updatecontrols = (data:any,userId:any)=> {
  return axios
    .post(`${IH_URL}/updateMailSend/${userId}`, data,getToken())
    .then((response) => {
      
      return response.data
     })
   
}
const getInsuranceHolderById = (id:any) => {
  return axios
    .get(`${IH_URL}/${id}`,getToken()) 
    .then((response) =>{
    //
      return response.data;
    }
   )
}
const updateBasicInsuranceHolder = (insuranceholder:any,ID:any): Promise<InsuranceHolder | undefined> => {
  //
  return axios
  .put(`${IH_URL}/${ID}`, insuranceholder,getToken())
  .then((response) => {
  //  
    return response.data
   })
   
}
const updateFamilyInsuranceHolder = (insuranceholder:any,ID:any): Promise<InsuranceHolder | undefined> => {
 // 
  return axios
  .put(`${IH_URL}/family/${ID}`, insuranceholder,getToken())
  .then((response) => {
  //  
    return response.data
   })
   
}
const getCompany = async (): Promise<InsuranceHolderQueryResponse> => {
  const res = await axios.get(`${IH_URL}/getCompany`, getToken());
  //
  return res;
}
const getDepartment = async (clientID:any): Promise<InsuranceHolderQueryResponse> => {
  //
  const res = await axios.get(`${IH_URL}/getDepartment/${clientID}`, getToken());
  //
  return res;
}
const adimingetDepartment = async (): Promise<InsuranceHolderQueryResponse> => {
  //
  const res = await axios.get(`${IH_URL}/admingetDepartment`, getToken());
  //
  return res;
}
const createInsuranceHolder = (insuranceholder: FormData,clientID:any): Promise<InsuranceHolder | undefined> => {
  //
  return axios
  .post(`${IH_URL}/addInsuranceHolder/${clientID}`, insuranceholder,getToken())
  .then((response) => {
  //  
    return response.data
   })
   
}

const updatebulkinsert = (excel: any) => {
  return axios
    .post(`${EXCEL_URL}`, excel,getToken())
    .then((response) => {

    //  
      return response.data;
    // .then((response: AxiosResponse<Response<InsuranceHolder>>) => response.data)
    // .then((response: Response<InsuranceHolder>) => response.data)
})}

const getSubSob = async (): Promise<InsuranceHolderQueryResponse> => {
  const res = await axios.get(`${SOB_URL}`, getToken());
  return res;
}
const getPlan= async (id:any) => {
  const res = await axios.get(`${API_URL}/report/clientReport/getAssignedPlans/${id}`, getToken())
  return res;
}
const getCountry = async (): Promise<InsuranceHolderQueryResponse> => {
  const res = await axios.get(`${COUNTRY_URL}`, getToken());
  //
  return res;
}

const deleteInsuranceHolderById = (id: any) => {
  return axios
    .post(`${IH_URL}/${id}`, {}, getToken())
    .then((response) => {

  //  
    return response.data;

    }
    )
}
const updateActiveDeactive = (id: any,status:any) => {
  return axios
    .get(`${IH_URL}/activateDeactivateAccount/${id}/${status}`, getToken())
    .then((response) => {

  //  
    return response.data;

    }
    )
}
const getsobdetails = async (id: string)=> {
  // 
   const res = await axios.get(`${GET_PLAN_LIST}/${id}`, getToken());
  //
   return res;
 }
 const getReportMonthlyCount = async (date: string,comid:any)=> {
  // 
   const res = await axios.post(`${API_URL}/report/employeeMonthlyHeadCountReport`,{date,comid}, getToken());
  //
   return res;
 }
 const upgradePlan=async(data:any)=>{
  const res = await axios.put(`${API_URL}/plan/upgrade/new/plan`,data, getToken());
  //
   return res.data;
 }
 const renewalPlan=async(data:any)=>{
  const res = await axios.put(`${API_URL}/plan/renewal/oldPlan`,data, getToken());
  //
   return res.data;
 }
 const cronJob=async()=>{ console.log("call----------");
  const res = await axios.get(`${API_URL}/cron/updateCurrentBalance`,getToken());
  //
   return res.data;
 }
export {
  getInsuranceHolder,
  deleteInsuranceHolderById,
  getSubSob,updatecontrols,getsobdetails,getReportMonthlyCount,
  createInsuranceHolder,getInsuranceHolderById,updateFamilyInsuranceHolder,
  getCompany, getDepartment,getCountry,updatebulkinsert,updateBasicInsuranceHolder,
  adimingetDepartment,updateActiveDeactive,getPlan,upgradePlan,renewalPlan,cronJob
}
