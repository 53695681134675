// @ts-nocheck
import {Column} from 'react-table'
import {ClaimInfoCell,ClaimInfoCellStatus,ClaimInfoCellCreated,ClaimInfoCellEmp} from './ClaimformInfoCell'
import {ClaimformActionsCell} from './ClaimformActionsCell'
import {ClaimformSelectionCell} from './ClaimformSelectionCell'
import {ClaimformSelectionHeader} from './ClaimformSelectionHeader'


import {ClaimformCustomHeader} from './ClaimformCustomHeader'

import {Claim} from '../../core/_models'

const claimformColumns: ReadonlyArray<Column<Claim>> = [
  {
    Header: (props) => <ClaimformSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <ClaimformSelectionCell id={props.data[props.row.index].primaryId
    } />,
  },
  {
    Header: (props) => <ClaimformCustomHeader tableProps={props} title='#' className='min-w-100px' />,
    accessor: 'serialNo',
   
  },
 
  {
    Header: (props) => (
      <ClaimformCustomHeader tableProps={props} title='Patient' className='min-w-125px' />
    ),
    accessor: 'familymember.patientName',
    Cell: ({...props}) => <ClaimInfoCell claim={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ClaimformCustomHeader tableProps={props} title='Clinic' className='min-w-125px' />
    ),
    accessor: 'clinic.clinicName',
  },
  {
    Header: (props) => (
      <ClaimformCustomHeader tableProps={props} title='Company' className='min-w-125px' />
    ),
    accessor: 'client.clientName',
  },
  {
    Header: (props) => (
      <ClaimformCustomHeader tableProps={props} title='Reference No' className='min-w-125px' />
    ),
    accessor: 'uniqueId',
  },
 
  {
    Header: (props) => (
      <ClaimformCustomHeader tableProps={props} title='Amount ( RM )' className='min-w-125px' />
    ),
    accessor: 'amount',
  },
  {
    Header: (props) => (
      <ClaimformCustomHeader tableProps={props} title='Approval Status' className='min-w-125px' />
    ),
    accessor: 'approvalStatus',Cell: ({...props}) => <ClaimInfoCellStatus claim={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ClaimformCustomHeader tableProps={props} title='Created' className='min-w-125px' />
    ),
    accessor: 'familymember.createdAt',
    Cell: ({...props}) => <ClaimInfoCellCreated claim={props.data[props.row.index]} />,
  },
  
  { 
  
    Header: (props) => (
      <ClaimformCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ClaimformActionsCell id={props.data[props.row.index].id} claimform={props.data[props.row.index]}/>,
  },
]

export {claimformColumns}
