import { createContext } from "react";

type AlertContextType = {
  show: boolean;
  message: any;
  type: any;
  showAlert: (message: any,type:any) => void;
  hideAlert: (e : any) => void;
};

export const AlertContext = createContext<AlertContextType>({
  show: false,
  message: "",
  type: "",
  showAlert: (message: any,type:any) => {},
  hideAlert: (e : any) => {},
});