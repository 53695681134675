/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {removeAuth, useAuth} from '../../../../../app/modules/auth'
import {getrolewisesidemenu} from '../../../../../app/modules/apps/role-access-setting/role-setting-list/core/_requests'

const SidebarMenuMain = () => {
  const [sidemenu, setsidemenu] = useState<any>([])

  const intl = useIntl()
  const {currentUser, logout} = useAuth()
  // var Localtype = localStorage.getItem('rakyat_auth_detail');
  const currentRole = currentUser?.role
  useEffect(() => {
    getrolewisesidemenu(currentRole).then((res: any) => {
      const sidemenuitems = res.data.Data
      const result = sidemenuitems.reduce(
        (acc: {[x: string]: any[]}, item: {module: {sbtitle: {subtitileName: any}}}) => {
          const sbtitleName = item.module.sbtitle.subtitileName
          if (!acc[sbtitleName]) {
            acc[sbtitleName] = []
          }
          acc[sbtitleName].push(item)
          return acc
        },
        {}
      )

      const restructuredData = Object.keys(result).map((key) => ({
        [key.toLowerCase()]: result[key],
      }))

      setsidemenu(restructuredData)
    })
  }, [])

  if (currentUser?.type == '1' || currentUser?.Type == 'adminEmployee') {
    return (
      <>
        {sidemenu.map((item: any, i: any) => {
          const currentSubtitle = Object.keys(item)[0]

          return (
            <>
              <div key={i}>
                <div className='menu-item'>
                  <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                      {currentSubtitle}
                    </span>
                  </div>
                </div>
                {item[currentSubtitle].map((module: any, j: any) => (
                  <SidebarMenuItem
                    key={j}
                    to={module.module?.link}
                    icon={module.module?.icon}
                    title={module.module?.name}
                    fontIcon='bi-app-indicator'
                  />
                ))}
              </div>
            </>
          )
        })}
      </>
      //employeeReport
    )
  } else if (currentUser?.type == '3' || currentUser?.Type == 'clinic') {
    return (
      <>
        {sidemenu.map((item: any, i: any) => {
          const currentSubtitle = Object.keys(item)[0]

          return (
            <div key={i}>
              <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                    {currentSubtitle}
                  </span>
                </div>
              </div>
              {item[currentSubtitle].map((module: any, j: any) => (
                <SidebarMenuItem
                  key={j}
                  to={module.module?.link}
                  icon={module.module?.icon}
                  title={module.module?.name}
                  fontIcon='bi-app-indicator'
                />
              ))}
            </div>
          )
        })}
      </>
    )
  } else if (currentUser?.type == '2' || currentUser?.Type == 'client') {
    return (
      <>
        {sidemenu.map((item: any, i: any) => {
          const currentSubtitle = Object.keys(item)[0]

          return (
            <div key={i}>
              <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                    {currentSubtitle}
                  </span>
                </div>
              </div>
              {item[currentSubtitle].map((module: any, j: any) => (
                <SidebarMenuItem
                  key={j}
                  to={module.module?.link}
                  icon={module.module?.icon}
                  title={module.module?.name}
                  fontIcon='bi-app-indicator'
                />
              ))}
            </div>
          )
        })}
      </>
    )
  } else {
    return null
  }
}

export {SidebarMenuMain}
