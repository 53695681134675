import React from 'react'
import {KTSVG} from '../../../../../../../../_metronic/helpers'
import { MyContext } from '../../ContextFile'
import {useListView} from '../../core/ListViewProvider'
import {UsersListFilter} from './UsersListFilter'

const UsersListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  
  const { state, setState } = React.useContext(MyContext);
const openAddUserModal=()=>{
  setState({name:'',primaryId:'',editclicked:false})
}
  return (
    <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Role
      </button>
      
     
  )
}

export {UsersListToolbar}
