import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
 

import React, { useEffect, useState } from 'react'
import { ClinicPaymentFilter } from './ClinicPaymentFilter'
interface Event<T = EventTarget> {
  target: T
  // ...
}
const InsuranceHolderListToolbar = () => {
   
  
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const openAddUserModal = () => {
    navigate('/clinic-payment/add')
  }
   
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <CompanyListFilter /> */}
<ClinicPaymentFilter/>
     
     
    
    </div>
  )
}

export {InsuranceHolderListToolbar}
