import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../../_metronic/helpers'
import {ClinicSupport, ClinicQueryResponse} from './_models'
import {getToken} from '../../../../../auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS
const SUP_URL = `${API_URL}/support`
 
 
//const DEL_CLINIC_URL=`${API_URL}/clinic`
var AuthVerify=getToken.call(this);


//
const getClientSupport = (query: string,primaryId:any): Promise<ClinicQueryResponse> => { 
  return axios
    .post(`${SUP_URL}/clientIndex/query?${query}`,{primaryId},getToken())
    .then((d: AxiosResponse<ClinicQueryResponse>) => {
    // 
      return d.data
    })
} 
const getpriority = async (): Promise<ClinicQueryResponse> => {
  const res = await axios.get(`${SUP_URL}/getsupportPriority`, getToken());
 // 
  return res;
}
const createClientSupport = (clinic: ClinicSupport): Promise<ClinicSupport | undefined> => {
  return axios
    .post(`${SUP_URL}/addClientSupport`, clinic,getToken())
    .then((response) => {
     // 
      return response.data
     })
   
}
const viewclinicSupport = (primaryId:any) => {
  return axios
    .get(`${SUP_URL}/viewClinicSupport/${primaryId}`,getToken())
    .then((response) => {
     // 
      return response.data
     })
   
}
const updateclinicSupport = (data: ClinicSupport,id:any): Promise<ClinicSupport | undefined> => {
 
  return axios
    .post(`${SUP_URL}/editClinicSupport/${id}`,data,getToken())
    .then((response) => {
     // 
      return response.data
     })
   
}
const deleteclinicSupport = (id:any) => {

  return axios
    .get(`${SUP_URL}/deleteClinicSupport/${id}`,getToken())
    .then((response) => {
     // 
      return response.data
     })
   
}

 
 
export {
  getClientSupport,getpriority,createClientSupport,viewclinicSupport,updateclinicSupport,deleteclinicSupport
  
}
