/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Employee} from '../../core/_models'
import Avatar from 'react-avatar';
import { getClientByPlanId } from '../../../../assign-subsob/assign-subsob-list/table/_requests';

type Props = {
  clinic: Employee
}

const EmployeeInfoCell: FC<Props> = ({clinic}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
    <Avatar size="40"  round="20px" maxInitials={2}
            name={clinic?.name}
        />

    </div>
    <div className='d-flex flex-column'>
      <a  className='text-gray-800  mb-1'>
        {clinic?.name}
      </a>
      
    </div>
  </div>
)
const ClientEmployeeInfoCell: FC<Props> = ({clinic}) =>{
  const [com,setCom]=useState([])
 // 
  getClientByPlanId(clinic.primaryId).then((res)=>{
   // 
   setCom(res.Data);
  })
 
  return(
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
     
    <div className='d-flex flex-column'>
    <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
      { com.map((item: any, i: any) => {
       // 
                       return (
                        
                        <a className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3 m-2'  key={i}>
                          {item?.client?.clientname}
                        </a>
                        
                           )})}
      </div>
      
    </div>
  </div>
)
}
export {EmployeeInfoCell,ClientEmployeeInfoCell}
