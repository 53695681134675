import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';
import { viewclinicSupport } from '../client-support-list/core/_requests';
// import { getClinicById } from '../clinic-support-list/core/_requests';  
import {EditBasicDetails} from './EditBasicDetails'

export const EditBasicDetailsWrapper = () => {
 const { id } = useParams();
  const [data, setData] = useState<any>(null) 
  useEffect(() => { 
    viewclinicSupport(id).then((res) => {
      
      setData(res.Data[0])
    })
  }, [])
  if (data) {
    return <EditBasicDetails initialValues={data} />
  }
  return null
}
 