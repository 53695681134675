import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {listRecentrecentInsuranceHolder, initialValuesIH} from '../core/_models'
import {recentInsuranceHolder} from '../core/_request'
import Avatar from 'react-avatar'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {getCRUD} from '../../modules/apps/companies/company-list/core/_requests'

type Props = {
  className: string
}

const EmployeeTable: React.FC<Props> = ({className}) => {
  const navigate = useNavigate()
  //settings
  const [data, setData] = useState<listRecentrecentInsuranceHolder>(initialValuesIH)
  const [insuranceHolder, setInsuranceHolder] = useState<any>([])
  const updateData = (fieldsToUpdate: Partial<listRecentrecentInsuranceHolder>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }
  const viewEmployeeDetails = (Id: any) => {
    navigate('/insurance-holder/view/' + Id)
  }
  useEffect(() => {
    recentInsuranceHolder().then((res: any) => {
      setInsuranceHolder(res.data.data)
      
    })
  }, [])
  const {currentUser} = useAuth()
  const [empaccess, setempaccess] = useState<any>([])

  useEffect(() => {
    getCRUD(currentUser?.role, 11).then((res: any) => {
      var crudres = res.data?.Data[0]
      setempaccess(crudres)
    })
  }, [])
  //settings
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Employees</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            List of recently created Employees
          </span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to view all employee'
        >
          {empaccess?.list === 1 ? (
            <Link
              to='/insurance-holder/list'
              className='btn btn-sm btn-primary'
              // data-bs-toggle='modal'
              // data-bs-target='#kt_modal_invite_friends'
            >
              <KTSVG path='media/icons/duotune/files/fil001.svg' className='svg-icon-3' />
              View All
            </Link>
          ) : (
            ''
          )}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Employee Name</th>
                <th className='min-w-150px'>Company Name</th>
                <th className='min-w-140px'>Department</th>

                <th className='min-w-120px'>Email</th>
                <th className='min-w-120px'>Contact</th>
                {empaccess?.view === 1 ? <th className='min-w-100px text-end'>Actions</th> : ''}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {insuranceHolder?.map((item: any) => {
                return (
                  <tr key={item?.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          <Avatar size='40' round='20px' name={item?.name} maxInitials={2} />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <a  className='text-dark fw-bold  fs-6'>
                            {item?.name.slice(0, 20)}
                            {item?.name.length > 20 && '...'}
                          </a>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {item?.employeeId}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      {item?.client?.name.slice(0, 20)}
                      {item?.client?.name.length > 20 && '...'}
                    </td>
                    <td>{item?.department?.name}</td>

                    <td>
                      {item?.email.slice(0, 20)}
                      {item?.email.length > 20 && '...'}
                    </td>
                    <td>{item?.mobileNumber}</td>

                    {empaccess?.view === 1 ? (
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <a
                            onClick={() => viewEmployeeDetails(item?.id)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/eye.svg'
                              className='svg-icon-3'
                            />
                          </a>

                          {/* <a
                      
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a> */}
                        </div>
                      </td>
                    ) : (
                      ''
                    )}
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {EmployeeTable}
