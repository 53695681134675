import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';
 
 
import { EditContact } from './EditContact';
import { getBackendSettings } from '../plan-list/core/_requests';

export const EditPlanWrapper = () => {
 const { id } = useParams();
  const [data, setData] = useState<any>(null)
  useEffect(() => { 
    getBackendSettings().then((res) => {
     
      setData(res)
    })
  }, [])
  if (data) {
    
    return <EditContact initialValues={data} />
  }
  return null
}