import clsx from 'clsx'
import Avatar from 'react-avatar'
import { useAuth } from '../../../../app/modules/auth'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const {config} = useLayout()
  const {currentUser, logout} = useAuth()
  var MainName="";
  if(currentUser?.type=="1" || currentUser?.Type=="adminEmployee"){
    var MainName="Admin";
    }else if(currentUser?.Type=="clinic" || currentUser?.type=="3"){
      var MainName="Clinic";
    }
    else if(currentUser?.Type=="client" ||  currentUser?.type=="2"){
      var MainName="Company";
    }
  return (
    <div className='app-navbar flex-shrink-0'>
     {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      
      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      
      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <Avatar size="40"  round="20px" maxInitials={2} name={MainName}></Avatar>
          {/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='' />  */}
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            {/* <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} /> */}
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
