/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import {  StatisticsWidget3 } from './StatisticsWidget3'
import { ClinicTable } from './ClinicTable'


 
import React , { useEffect, useState }from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../_metronic/helpers'
import { ClientTable } from './ClientTable'
import { useAuth } from '../../../modules/auth'
import { getsupportwidget, widgetCount } from '../../../pages/core/_request'
import { getCRUD } from '../companies/company-list/core/_requests'
import { EmployeeTable } from './EmployeeTable'



const SupportWrapper: FC = () => {
  //Current Date
  const current = new Date();
  const dateCurrent = new Date().toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"});
  ////Current Date
  const intl = useIntl()
  //Data Set
  const [data, setData] = useState<any>();
  const [count, setCount] = useState<any>([]);
  const [claimcount, setClaimCount] = useState<any>([]);
   const updateData = (fieldsToUpdate: Partial<any>): void => {
  const updatedData = Object.assign(data, fieldsToUpdate)
  setData(updatedData)
  }

  useEffect(() => {
    widgetCount().then((res: any) => {      
    setCount(res.data.data)   
    });
  }, [])
 
  const {currentUser}=useAuth();
 
  const [widget, setwidget] = useState<any>([]);
  useEffect(() => {
    getsupportwidget().then((res: any) => {
      var rest=res.data?.data;
      setwidget(rest);

    })
  }, [])
  return (
    <>
<div className='card-header  row  '> 
      <div className='border-0 col-xl-9 mb-5 '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Support Overview</span>
        </h3> <span className='text-muted mt-1 fw-semibold fs-7'>Dashboard</span>
        <span className='text-muted mt-1 fw-semibold fs-7'> - </span>
        <span className='text-muted mt-1 fw-semibold fs-7'>Support Overview</span>
      
      </div>
       
      </div>
      
      <>
    {/* begin::Row */}
    
    <div className='row g-5 g-xl-8'>
    
      <div className='col-xl-4'>  <Link to='/client-support/list'>
        <StatisticsWidget3
          className='card-xl-stretch mb-xl-8'
          color='success'
          title='Total Clients Supports'
          description='Total number of supports to clients'
          change={widget?.clientsupport}
        /> </Link>
      </div>
     
    
      <div className='col-xl-4'>  <Link to='/clinic-support/list'>
        <StatisticsWidget3
          className='card-xl-stretch mb-xl-8'
          color='danger'
          title='Total Clinics Supports'
          description='Total number of supports to clinics'
          change={widget?.clinicsupport}
        /> </Link>
      </div>
     
     
      <div className='col-xl-4'>
      <Link to='/employeeSupport/list'>
        <StatisticsWidget3
          className='card-xl-stretch mb-5 mb-xl-8'
          color='primary'
          title='Total Insuranceholders Supports'
          description='Total number of supports from insuranceholder'
          change={widget?.employeesupport}
        /> </Link>
      </div>
     
    </div>
    {/* end::Row */}
    <div className='col-xl-12'>
      <ClientTable
        className='card-xl-stretch mb-5 mb-xl-8'
       />
    </div>
  <div className='col-xl-12'>
      <ClinicTable
        className='card-xl-stretch mb-5 mb-xl-8'
       />
    </div>
    {/* end::Row */}
   
    <div className='col-xl-12'>
      <EmployeeTable
        className='card-xl-stretch mb-5 mb-xl-8'
       />
    </div>
  </>
    </>
  )
}

export { SupportWrapper }
